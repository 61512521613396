import React, { Component } from "react";
import "./Titlebar.scss";
import { useTranslation } from "react-i18next";
import User from "../../models/User";
import { inject, observer } from "mobx-react";
import { UserStore } from "../../stores/user.store";

const greetingKeys = ["hey", "hello", "hi", "greetings", "localGreeting"];

interface TitleBarProps {
  userStore?: UserStore;
}

const Titlebar = ({ userStore }: TitleBarProps) => {
  const { t } = useTranslation(["greetings"]);

  if (!userStore || !userStore.currentUser) {
    return <></>;
  }

  const getRandomGreeting = () => {
    return greetingKeys[Math.floor(Math.random() * greetingKeys.length)];
  };

  return (
    <div className="titleBarContainer">
      <div className="greetingContainer">
        <div className="greeting">{t(getRandomGreeting()) + ", "}</div>
      </div>
      <div className="userName">{`${userStore.currentUser.firstName} ${userStore.currentUser.lastName}!`}</div>
    </div>
  );
};

export default inject("userStore")(observer(Titlebar));
