import React from "react";
import "./Container.scss";

const Container = ({ children, fullHeight }: any) => {
  return (
    <div className="container">
      <div className={fullHeight ? "content-full-height" : "content"}>
        {children}
      </div>
    </div>
  );
};

export default Container;
