import { inject, observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import "./ShipmentDetail.scss";
import Moment from "react-moment";
import { ShipmentStore } from "../../../stores/shipment.store";
import QRCode from "qrcode.react";
import backIcon from "../../../assets/icons/back.svg";
import { ModalStore } from "../../../stores/modal.store";
import { UserStore } from "../../../stores/user.store";
import { useTranslation } from "react-i18next";
import Shipment from "../../../models/Shipment.model";
import ShipmentCard from "../../shipmentcard/ShipmentCard";
import { MyCubeStore } from "../../../stores/mycube.store";
import LoadingScreen from "../../loadingscreen/LoadingScreen";
import PrimaryButton from "../../primarybutton/PrimaryButton";
import { useHistory } from "react-router-dom";
import Container from "../../container/Container";
import FloatingBack from "../../floatingback/FloatingBack";

interface ShipmentDetailProps {
  shipmentStore?: ShipmentStore;
  modalStore?: ModalStore;
  userStore?: UserStore;
  myCubeStore?: MyCubeStore;
}

const ShipmentDetail = ({ shipmentStore, modalStore }: ShipmentDetailProps) => {
  const [qrCode, setQrCode] = useState("");
  const [receivedAt, setReceivedAt] = useState<Date>();
  const [shipment, setShipment] = useState<Shipment>();
  const { t } = useTranslation();

  useEffect(() => {
    const getUrlParameter = (name: string) => {
      name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
      let regex = new RegExp("[\\?&]" + name + "=([^&#]*)");
      let results = regex.exec(window.location.search);
      return results === null
        ? ""
        : decodeURIComponent(results[1].replace(/\+/g, " "));
    };
    const key = getUrlParameter("shipmentid");
    shipmentStore!.currentShipment = undefined;
    shipmentStore?.setShipmentByShareToken(key);
  }, []);

  useEffect(() => {
    if (shipmentStore!.currentShipment) {
      setShipment(shipmentStore!.currentShipment);
    }
  }, [shipmentStore!.currentShipment]);

  useEffect(() => {
    if (shipmentStore!.currentShipment) {
      setQrCode(shipmentStore!.currentShipment.confirmationcode);
      setReceivedAt(shipmentStore!.currentShipment.receivedat);
    }
  }, [shipmentStore!.currentShipment]);

  if (!shipment) {
    return <LoadingScreen />;
  }

  return (
    <Container>
      <div className="shipment-details">
        <div className="header" style={{ height: window.innerWidth }}>
          <FloatingBack />
          <div
            onClick={() => {
              modalStore?.openModal("qrcode");
            }}
          >
            <QRCode
              bgColor="transparent"
              fgColor="#000000"
              value={qrCode}
              size={window.innerWidth <= 600 ? window.innerWidth * 0.8 : 250}
            />
          </div>
        </div>
        <div className="content">
          <ShipmentCard
            shipment={shipment}
            className="shipment-card"
            maxed
            enableEdit
            notClickable
          />
          {shipment && shipment.received && (
            <div className="parcle-pickedup">
              Das Paket wurde am{" "}
              <Moment format="HH:mm DD.MM.YYYY" date={receivedAt} />
              abgeholt.
            </div>
          )}
          {shipment && !shipment.received && (
            <PrimaryButton
              className="share-shipment"
              label={t("share")}
              onClick={() => {
                modalStore?.openModal("shareshipment");
              }}
            />
          )}
        </div>
      </div>
    </Container>
  );
};

export default inject(
  "userStore",
  "shipmentStore",
  "modalStore",
  "myCubeStore"
)(observer(ShipmentDetail));
