import React from "react";
import "./MaxWidthDiv.scss";
import classNames from "classnames";

const MaxWidthDiv = ({ children, small, mid, large, className }: any) => {
  const divStyle = classNames({
    "small-container": small,
    "mid-container": mid,
    "large-container": large,
  });
  return <div className={`${divStyle} ${className}`}>{children}</div>;
};

export default MaxWidthDiv;
