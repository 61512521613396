import React from "react";
import classNames from "classnames";
import "./PrimaryButton.scss";
import Loader from "../loader/Loader";

interface PrimaryButtonProps {
  label: string;
  onClick: Function;
  submitButton?: boolean;
  inverted?: boolean;
  loading?: boolean;
  disabled?: boolean;
  className?: any;
}

const PrimaryButton = ({
  label,
  onClick,
  submitButton,
  disabled,
  inverted,
  loading,
  className,
}: PrimaryButtonProps) => {
  const buttonStyle = classNames({
    "primary-button": !inverted,
    "primary-button-inverted": inverted,
    "primary-button-disabled": disabled,
  });
  if (loading) {
    return (
      <div className={`${buttonStyle} ${className}`}>
        <Loader />
      </div>
    );
  }
  if (submitButton) {
    return (
      <button className={`${buttonStyle} ${className}`} type="submit">
        {label}
      </button>
    );
  }

  if (disabled) {
    return <div className={`${buttonStyle} ${className}`}>{label}</div>;
  }

  return (
    <div
      className={`${buttonStyle} ${className}`}
      onClick={() => {
        if (onClick) {
          onClick();
        }
      }}
    >
      {label}
    </div>
  );
};

export default PrimaryButton;
