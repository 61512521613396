import { inject, observer } from "mobx-react";
import "./ShopCard.scss";
import React, { useEffect, useState } from "react";
import Card from "../../components/card/Card";
import { ShopStore } from "../../stores/shop.store";
import Title from "../../components/title/Title";
import PrimaryButton from "../../components/primarybutton/PrimaryButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinus, faPlus, faTrashAlt } from "@fortawesome/pro-solid-svg-icons";
import { OrderPart } from "../../models/Order.model";
import BackHeader from "../../components/backheader/BackHeader";
import ShoppingCard from "../ShoppingCardItem.tsx/ShoppingCard";
import { useHistory } from "react-router-dom";
interface ShopCardInterface {
  shopStore?: ShopStore;
}

const ShopCard = ({ shopStore }: ShopCardInterface) => {
  const history = useHistory();
  const [totalPrice, setTotalPrice] = useState(0);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const orderPartsString = localStorage.getItem("orderparts");
    if (orderPartsString) {
      const orderParts = JSON.parse(orderPartsString);
      shopStore?.setOrderParts(orderParts);
    }
  }, []);

  useEffect(() => {
    calculatePrice();
  }, [shopStore?.orderParts]);

  const renderShopCardItems = () => {
    return shopStore?.orderParts.map((orderPart, index) => {
      return (
        <div key={index}>
          <ShoppingCard orderPart={orderPart} />
        </div>
      );
    });
  };

  const addProduct = (orderPart: OrderPart) => {
    orderPart.amount += 1;
    shopStore?.findAndUpdateOrderPart(orderPart);
  };

  const subProduct = (orderPart: OrderPart) => {
    if (orderPart.amount > 1) {
      orderPart.amount -= 1;
      shopStore?.findAndUpdateOrderPart(orderPart);
    }
  };

  const amountPicker = (orderPart: OrderPart) => {
    return (
      <div className="price">
        <div className="amount-picker">
          <div
            className="center"
            onClick={() => {
              subProduct(orderPart);
            }}
          >
            <FontAwesomeIcon
              icon={faMinus}
              color="#269ab2"
              style={{ fontSize: 8 }}
            />
          </div>
          <div className="amount-input">{orderPart.amount}</div>
          <div
            className="center"
            onClick={() => {
              addProduct(orderPart);
            }}
          >
            <FontAwesomeIcon
              icon={faPlus}
              color="#269ab2"
              style={{ fontSize: 8 }}
            />
          </div>
        </div>
        <div className="total-price">
           € {orderPart.shopitem.price.toFixed(2)}
        </div>
        <div
          className="trashCan"
          onClick={() => {
            shopStore?.findAndDeleteOrderPart(orderPart);
          }}
        >
          <FontAwesomeIcon icon={faTrashAlt} color="#c36" size="sm" />
        </div>
      </div>
    );
  };

  const calculatePrice = () => {
    let price = 0;
    const orderparts = shopStore?.orderParts;
    if (orderparts && orderparts.length > 0) {
      for (let part of orderparts) {
        price += part.amount * part.shopitem.price;
      }
      setTotalPrice(price);
    }
  };

  const isItemInShopCard = () => {
    return shopStore?.orderParts && shopStore?.orderParts.length > 0;
  };

  return (
    <div className="shop-card-container">
      <BackHeader title="Einkaufswagen" />
      <div className="scrollable-items">{renderShopCardItems()}</div>
      <div className="footer">
        {isItemInShopCard() && (
          <div className="amount">
            <div className="">Gesamtpreis</div>
            <div>{totalPrice.toFixed(2)} €</div>
          </div>
        )}
        <PrimaryButton
          label="Bestellen"
          loading={loading}
          disabled={!isItemInShopCard()}
          onClick={async () => {
            setLoading(true);
            await shopStore?.createOrder();
            setLoading(false);
            history.push("/dashboard")
          }}
        />
      </div>
      <div style={{ height: 120 }}></div>
    </div>
  );
};

export default inject("shopStore")(observer(ShopCard));
