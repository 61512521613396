import { action, observable, makeObservable } from "mobx";
import Cube from "../models/Cube.model";
import MyCube from "../models/MyCube.model";
import { loadCube, loadCubes } from "../services/HttpAuth.service";

export class CubeStore {
  @observable
  cubes: Cube[] = [];

  @observable
  currentMyCube: MyCube | undefined;

  @observable
  isAuthenticated: boolean = false;

  constructor() {
    makeObservable(this);
  }

  @action
  public async loadAllCubes() {
    try {
      const response = await loadCubes();
      if (response.status === 200) {
        this.cubes = response.data as Cube[];
        return true;
      }
    } catch (err) {
      console.log(err);
      return false;
    }
  }

  @action
  public async setMyCube(myCube: MyCube) {
    try {
      const response = await loadCube(myCube.cube.id);
      if (response.status === 200) {
        myCube!.cube = (await response.data) as Cube;
      }
    } catch (err) {}
    this.currentMyCube = myCube;
  }
}
