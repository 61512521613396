import React from "react";
import ShopCardButton from "../shopcardbutton/ShopCardButton";
import './ShopHeader.scss';

const ShopHeader = () => {
  return (
    <div className="shop-header">
      <div className="title">Shop</div>
      <div className="right">
        <ShopCardButton />
      </div>
    </div>
  );
};

export default ShopHeader;
