import React from 'react'

const ForgotPassword = () => {
    return (
        <div>
            FG PASSWORD
        </div>
    )
}

export default ForgotPassword
