import { inject, observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import "./OrderDetail.scss";
import Moment from "react-moment";
import QRCode from "qrcode.react";
import backIcon from "../../../assets/icons/back.svg";
import { useHistory, useParams } from "react-router-dom";
import OrderCard from "../OrderCard/OrderCard";
import Order from "../../models/Order.model";
import LoadingScreen from "../loadingscreen/LoadingScreen";
import Container from "../container/Container";
import FloatingBack from "../floatingback/FloatingBack";
import { ModalStore } from "../../stores/modal.store";
import { ShopStore } from "../../stores/shop.store";

interface OrderDetailProps {
  modalStore?: ModalStore;
  shopStore?: ShopStore;
}

const OrderDetail = ({ modalStore, shopStore }: OrderDetailProps) => {
  const [qrCode, setQrCode] = useState("");
  const [order, setOrder] = useState<Order | undefined>(undefined);

  const params = useParams();
  //@ts-ignore
  const id = params.id;

  useEffect(() => {
    setOrder(undefined);
    shopStore?.clearCurrentOrder();
    shopStore?.findOne(id);
  }, [id, shopStore?.allOrders]);

  useEffect(() => {
    if (shopStore?.currentOrder) {
      setOrder(shopStore?.currentOrder);
      if (shopStore?.currentOrder.confirmationcode) {
        setQrCode(shopStore?.currentOrder.confirmationcode);
      }
    }
  }, [shopStore?.currentOrder, shopStore?.allOrders]);

  if (!order || order == undefined) {
    return <LoadingScreen />;
  }

  return (
    <Container>
      <div className="order-details">
        <div className="header" style={{ height: window.innerWidth }}>
          <FloatingBack />
          {qrCode && (
            <QRCode
              bgColor="transparent"
              fgColor="#000000"
              value={qrCode}
              size={window.innerWidth <= 600 ? window.innerWidth * 0.8 : 250}
            />
          )}
          {!qrCode && (
            <div style={{ position: "relative" }}>
              <div className="order-center-container">
                <div className="order-center">
                  Deine Bestellung ist noch nicht angekommen.
                </div>
              </div>
              <QRCode
                bgColor="transparent"
                fgColor="#000000"
                value={qrCode}
                size={window.innerWidth <= 600 ? window.innerWidth * 0.8 : 250}
              />
            </div>
          )}
        </div>
        <div className="content">
          <OrderCard order={order} displayChild flat />
          <div style={{ height: 180 }}></div>
        </div>
      </div>
    </Container>
  );
};

export default inject("shopStore", "modalStore")(observer(OrderDetail));
