import React from "react";
import "./ProfileTabbarItem.scss";

interface ProfileTabbarItemProps {
  value: string;
  currentPage: string;
  pagename: string;
  onChange: any;
}

const ProfileTabbarItem = ({
  value,
  currentPage,
  pagename,
  onChange,
}: ProfileTabbarItemProps) => {
  const isThisSelected = currentPage === pagename;
  return (
    <div
      className="profile-tabbar-item mr-20"
      onClick={() => {
        onChange(pagename);
      }}
    >
      <div className={isThisSelected ? "item-selected" : "item"}>{value}</div>
      <div className={isThisSelected ? "dot-selected" : "dot"}></div>
    </div>
  );
};

export default ProfileTabbarItem;
