import { inject, observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { CubeStore } from "../../../stores/cubes.store";
import "./CubeDetails.scss";
import { ShipmentStore } from "../../../stores/shipment.store";
import ShipmentCard from "../../shipmentcard/ShipmentCard";
import backIcon from "../../../assets/icons/back.svg";
import { useTranslation } from "react-i18next";
import PrimaryButton from "../../primarybutton/PrimaryButton";
import { ModalStore } from "../../../stores/modal.store";
import MyCubeDetails from "../../mycubedetails/MyCubeDetails";
import { MyCubeStore } from "../../../stores/mycube.store";
import ModalCard from "../ModalCard/ModalCard";
import Loader from "../../loader/Loader";
import { useHistory } from "react-router-dom";

import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import Container from "../../container/Container";
import FloatingBack from "../../floatingback/FloatingBack";

interface CubeDetailsProps {
  cubeStore?: CubeStore;
  shipmentStore?: ShipmentStore;
  modalStore?: ModalStore;
  myCubeStore?: MyCubeStore;
}

const CubeDetails = ({ shipmentStore, myCubeStore }: CubeDetailsProps) => {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation(["cubes"]);
  const history = useHistory();

  const confirm = () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="delete-cube">
            <div className="title">{t("cubes:delete_title")}</div>
            <p>{t("cubes:delete_subtitle")}</p>
            <div className="delete-button">
              <PrimaryButton
                label={t("cubes:delete_cancle")}
                onClick={onClose}
              />
              <PrimaryButton
                label={t("cubes:delete_ok")}
                inverted
                onClick={async () => {
                  onClose();
                  setLoading(true);
                  if (await myCubeStore!.deleteCurrentMyCube()) {
                    history.push("/profile");
                  }
                  setLoading(false);
                }}
              />
            </div>
          </div>
        );
      },
    });
  };

  useEffect(() => {
    const getUrlParameter = (name: string) => {
      name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
      let regex = new RegExp("[\\?&]" + name + "=([^&#]*)");
      let results = regex.exec(window.location.search);
      return results === null
        ? ""
        : decodeURIComponent(results[1].replace(/\+/g, " "));
    };
    const key = getUrlParameter("cubeid");
    shipmentStore?.loadAllShipments();
   
    myCubeStore!.getMyCubeById(parseInt(key));
  }, []);

  useEffect(() => {
    if (myCubeStore && myCubeStore!.currentMyCube) {
      shipmentStore!.loadShipmentsForCube(myCubeStore.currentMyCube.cube.id);
    }
    return () => {};
  }, [myCubeStore?.currentMyCube, shipmentStore?.shipments]);

  const renderShipments = () => {
    return shipmentStore!.shipments.map((shipment, key) => {
      return (
        <ShipmentCard
          shipment={shipment}
          key={key}
          className="shipment-card"
          fromDetail
        />
      );
    });
  };

  if (loading) {
    return (
      <ModalCard>
        <div className="loader-container">
          <Loader size={50} />
        </div>
      </ModalCard>
    );
  }

  return (
    <Container fullHeight>
      <div className="cube-details">
        <FloatingBack />
        <MyCubeDetails currentMyCube={myCubeStore!.currentMyCube} showCode />
        <PrimaryButton
          loading={loading}
          label={t("cubes:delete_cube_detail")}
          onClick={async () => {
            confirm();
            // setLoading(true);
            // await myCubeStore!.deleteCurrentMyCube();
            // setLoading(false);
            // modalStore!.closeModal();
          }}
        />
        <div className="shipment-container">
          {shipmentStore!.shipments.length > 0 && (
            <div className="title">{t("common:shipments")}</div>
          )}
          {renderShipments()}
        </div>
      </div>
    </Container>
  );
};

export default inject(
  "shipmentStore",
  "modalStore",
  "myCubeStore"
)(observer(CubeDetails));
