import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import PrimaryButton from "../../primarybutton/PrimaryButton";
import TextInput from "../../TextInput/TextInput";

import ModalCard from "../ModalCard/ModalCard";
import TextHeaderBlock from "../../textheaderblock/TextHeaderBlock";
import "./ShareShipment.scss";
import { ModalStore } from "../../../stores/modal.store";
import { inject } from "mobx-react";
import { ShipmentStore } from "../../../stores/shipment.store";
import Loader from "../../loader/Loader";

interface ShareShipmentProps {
  modalStore?: ModalStore;
  shipmentStore?: ShipmentStore;
}

const ShareShipment = ({ modalStore, shipmentStore }: ShareShipmentProps) => {
  const { t } = useTranslation();
  const { register, handleSubmit, errors } = useForm();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const invitePerEmail = async (email: string) => {
    setLoading(true);
    if (shipmentStore!.currentShipment) {
      if (
        await shipmentStore!.invitePerEmail(
          email,
          shipmentStore!.currentShipment!.id
        )
      ) {
        modalStore!.closeModal();
      } else {
        setError(true);
      }
    }
    setLoading(false);
  };

  const onSubmit = async (data: any) => {
    const { email } = data;
    invitePerEmail(email);
  };

  return (
    <ModalCard className="shipment-modal-card">
      {error && (
        <div className="overlay">
          <div>
            {t("common:share_error")}
            <PrimaryButton
              className="mt-15"
              inverted
              label={t("common:share_ok")}
              onClick={() => {
                setError(false);
                modalStore!.closeModal();
              }}
            />
          </div>
        </div>
      )}
      {loading && (
        <div className="overlay">
          <div className="self-center">
            <Loader size={46} />
          </div>
        </div>
      )}
      <div className="share-shipment">
        <div className="content">
          <TextHeaderBlock
            subtitleClassName="text-block"
            title={t("common:share_title")}
            subtitle={t("common:share_subtitle")}
          />
          <form onSubmit={handleSubmit(onSubmit)} className="form">
            <TextInput
              name="email"
              placeholder={t("common:email")}
              error={errors["email"]}
              value={""}
              type="email"
              inputRef={register({
                required: true,
                pattern: /^\S+@\S+$/i,
              })}
            />
            <PrimaryButton
              className="mt-15"
              inverted
              label={t("common:share_button")}
              onClick={() => {}}
              submitButton
            />
          </form>
        </div>
      </div>
    </ModalCard>
  );
};

export default inject("modalStore", "shipmentStore")(ShareShipment);
