import React, { useEffect } from "react";
import { inject } from "mobx-react";
import { Route, useHistory } from "react-router"; // react-router v4
import { UserStore } from "../stores/user.store";

interface PrivateRouteProps {
  path: string;
  component: any;
  userStore?: UserStore;
}

const PrivateRoute = ({ path, component, userStore }: PrivateRouteProps) => {
  const history = useHistory();
  useEffect(() => {
    const checkUser = async () => {
      if (userStore && !(await userStore.getMe())) {
        history.push("/");
      }
    };
    checkUser();
  }, [history, userStore]);
  
  return <Route exact path={path} component={component} />;
};

export default inject("userStore")(PrivateRoute);
