import React from "react";
import './PriceDetails.scss';

interface PriceDetailsProps {
  price: string;
}

const PriceDetails = ({ price }: PriceDetailsProps) => {
  return (
    <div className="price-details">
      <div className="price">{price} €</div>
      <div className="price-title">Gesamt Preis</div>
    </div>
  );
};

export default PriceDetails;
