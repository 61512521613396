import React from "react";
import { useTranslation } from "react-i18next";
import "./ConfirmEmail.scss";
import headerImage from "../../assets/illustrations/undraw_happy_news_hxmt.svg";
import TextHeaderBlock from "../../components/textheaderblock/TextHeaderBlock";
import PrimaryButton from "../../components/primarybutton/PrimaryButton";
import { useHistory } from "react-router-dom";
import Container from "../../components/container/Container";
import LoginCheckPage from "../../components/LoginCheckPage/LoginCheckPage";

const ConfirmEmail = () => {
  const { t } = useTranslation(["verify"]);
  const history = useHistory();
  return (
    <LoginCheckPage>
      <Container>
        <div className="confirm-email">
          <img src={headerImage} className="header-image-illustration" />
          <div className="mt-30">
            <TextHeaderBlock
              darkBackground
              title={t("thankYou")}
              subtitle={t("emailIsVerified")}
            />
            <div className="mt-30">
              <PrimaryButton
                inverted
                onClick={() => {
                  history.push("/login");
                }}
                label={t("letsGo")}
              />
            </div>
          </div>
        </div>
      </Container>
    </LoginCheckPage>
  );
};

export default ConfirmEmail;
