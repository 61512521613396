import React, { useState } from "react";
import "./Navbar.scss";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBox, faUser, faStore } from "@fortawesome/pro-solid-svg-icons";
import { inject, observer } from "mobx-react";
import { UserStore } from "../../stores/user.store";

interface NavbarProps {
  userStore?: UserStore;
}

const Navbar = ({ userStore }: NavbarProps) => {
  const { t } = useTranslation();
  const location = useLocation();
  const history = useHistory();

  if (!userStore?.isAuthenticated) {
    return <></>;
  }
  return (
    <div className="tabbar-component">
      <div className="tabbar-inner-container">
        <div
          className={classNames({
            tab: true,
            "tab--active":
              location.pathname === "/dashboard" ||
              location.pathname.includes("/orderdetails") ||
              (location.pathname.includes("/shipmentdetails") &&
                !location.search.includes("from=profile")),
          })}
          onClick={() => {
            history.push("/dashboard");
          }}
        >
          <div className="badge-container">
            {/* <Badge count={this.props.shipments.length} /> */}
            <div className="tab-icon">
              <FontAwesomeIcon icon={faBox} />
            </div>
          </div>
          <div className="tab-text">{t("packages")}</div>
        </div>
        <div
          className={classNames({
            tab: true,
            "tab--active": location.pathname.includes("/shop"),
          })}
          onClick={() => {
            history.push("/shop");
          }}
        >
          <div className="tab-icon">
            <FontAwesomeIcon icon={faStore} />
          </div>
          <div className="tab-text">Shop</div>
        </div>
        <div
          className={classNames({
            tab: true,
            "tab--active":
              location.pathname.includes("/profile") ||
              location.pathname.includes("/cubedetails") ||
              location.search.includes("from=profile"),
          })}
          onClick={() => {
            history.push("/profile");
          }}
        >
          <div className="tab-icon">
            <FontAwesomeIcon icon={faUser} />
          </div>
          <div className="tab-text">{t("profil")}</div>
        </div>
      </div>
    </div>
  );
};

export default inject("userStore")(observer(Navbar));
