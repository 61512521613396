import { io } from "socket.io-client";

const TYPE = {
  UPDATE: "UPDATE",
  UPDATE_ONE: "UPDATE_ONE",
  CREATE: "CREATE",
};

const ENTITY = {
  SHIPMENT: "SHIPMENT",
  INVITE: "INVITE",
};

export default class Websocket {
  stores: any = {};
  started: boolean = false;

  constructor() {
    const url = process.env.REACT_APP_BACKEND_URL;
    if (url) {
      const socket = io(url, {
        auth: {
          token: localStorage.getItem("hugo_jwt"),
        },
      });

      socket.on(ENTITY.SHIPMENT, (inputmessage: any) => {
        if (inputmessage.type == TYPE.UPDATE) {
          this.stores["shipmentStore"].loadAllShipments();
          this.stores["shopStore"].loadAllOrders();
        }
        if (inputmessage.type == TYPE.UPDATE_ONE) {
          const { data } = inputmessage;
          this.stores["shipmentStore"].setShipmentByShareToken(data.sharetoken);
          this.stores["shipmentStore"].loadAllShipments();
          this.stores["shopStore"].loadAllOrders();
        }
      });
      socket.on(ENTITY.INVITE, (inputmessage: any) => {
        if (inputmessage.type == TYPE.CREATE) {
          this.stores["shipmentStore"].loadInvitations();
        }
      });
    }
  }

  addStore(key: string, store: any) {
    this.stores[key] = store;
  }
}
