import React from "react";
import "./ShoppingCard.scss";
import { OrderPart } from "../../models/Order.model";
import { ShopStore } from "../../stores/shop.store";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinus, faPlus, faTrashAlt } from "@fortawesome/pro-solid-svg-icons";
import Card from "../../components/card/Card";
import { inject, observer } from "mobx-react";

interface ShoppingCardProps {
  orderPart: OrderPart;
  shopStore?: ShopStore;
  hideAddSub?: boolean;
  flat?: boolean;
}

const ShoppingCard = ({
  orderPart,
  shopStore,
  hideAddSub,
  flat,
}: ShoppingCardProps) => {
  const addProduct = (orderPart: OrderPart) => {
    orderPart.amount += 1;
    shopStore?.findAndUpdateOrderPart(orderPart);
  };

  const subProduct = (orderPart: OrderPart) => {
    if (orderPart.amount > 1) {
      orderPart.amount -= 1;
      shopStore?.findAndUpdateOrderPart(orderPart);
    }
  };

  const amountPicker = (orderPart: OrderPart) => {
    return (
      <div className="price">
        <div className="amount-picker">
          <div
            className="center"
            onClick={() => {
              subProduct(orderPart);
            }}
          >
            {!hideAddSub && (
              <FontAwesomeIcon
                icon={faMinus}
                color="#269ab2"
                style={{ fontSize: 8 }}
              />
            )}
          </div>
          <div className="amount-input">{orderPart.amount}</div>
          <div
            className="center"
            onClick={() => {
              addProduct(orderPart);
            }}
          >
            {!hideAddSub && (
              <FontAwesomeIcon
                icon={faPlus}
                color="#269ab2"
                style={{ fontSize: 8 }}
              />
            )}
          </div>
        </div>
      
        {!hideAddSub && (
          <div
            className="trashCan"
            onClick={() => {
              shopStore?.findAndDeleteOrderPart(orderPart);
            }}
          >
            <FontAwesomeIcon icon={faTrashAlt} color="#c36" size="sm" />
          </div>
        )}
          <div className="total-price">
           € {orderPart.shopitem.price.toFixed(2)}
        </div>
      </div>
    );
  };

  if (flat) {
    return (
      <div className="shop-card">
        <div className="left">
          <img src={orderPart.shopitem.image.url} />
          <div className="center-header">
            <div className="title">{orderPart.shopitem.title}</div>
            <div className="short-description">
              {orderPart.shopitem.shortDescription}
            </div>
          </div>
        </div>
        <div>{amountPicker(orderPart)}</div>
      </div>
    );
  }

  return (
    <Card className="shop-card">
      <div className="left">
        <img src={orderPart.shopitem.image.url} />
        <div className="center-header">
          <div className="title">{orderPart.shopitem.title}</div>
          <div className="short-description">
            {orderPart.shopitem.shortDescription}
          </div>
        </div>
      </div>
      <div>{amountPicker(orderPart)}</div>
    </Card>
  );
};

export default inject("shopStore")(observer(ShoppingCard));
