import React, { useEffect } from "react";
import "./ShopCardButton.scss";
import shoppingCard from "../../assets/shopping_card.svg";
import { inject, observer } from "mobx-react";
import { ShopStore } from "../../stores/shop.store";
import { useHistory } from "react-router-dom";

interface ShopCardButtonProps {
  shopStore?: ShopStore;
}

const ShopCardButton = ({ shopStore }: ShopCardButtonProps) => {
  const length = shopStore!.orderParts.length;
  const history = useHistory();

  useEffect(() => {
    const orderPartsString = localStorage.getItem("orderparts");
    if (orderPartsString) {
      const orderParts = JSON.parse(orderPartsString);
      shopStore?.setOrderParts(orderParts);
    }
  }, []);

  return (
    <div
      className="shop-card-button"
      onClick={() => {
        history.push("shopcard");
      }}
    >
      <div className="container">
        {length > 0 && (
          <div className="counter">
            <div>{length}</div>
          </div>
        )}
        <img src={shoppingCard} />
      </div>
    </div>
  );
};

export default inject("shopStore")(observer(ShopCardButton));
