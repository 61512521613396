import { action, observable, makeObservable } from "mobx";
import CreateAddress from "../models/CreateAddress.model";
import CreateUser from "../models/CreateUser.model";
import UserLogin from "../models/Login.model";
import User from "../models/User";
import { register, login } from "../services/Http.service";
import {
  me,
  updateAddress,
  updateUser,
  addPayment,
} from "../services/HttpAuth.service";

export class UserStore {
  @observable
  currentUser: User | undefined;

  @observable
  isAuthenticated: boolean = false;

  @observable
  currentError: any |undefined;

  constructor() {
    makeObservable(this);
  }

  @action
  public loginUser = async (userLogin: UserLogin) => {
    try {
      const response = await login(userLogin);
      if (response.status === 200 && response.data) {
        const { user, jwt } = response.data;
        this.currentUser = user;
        window.localStorage.setItem("hugo_jwt", jwt);
        return true;
      }
    } catch (err) {
      this.currentError = err;
      return false;
    }
  };

  @action
  public registerUser = async (user: CreateUser) => {
    try {
      const response = await register(user);
      if (response.status === 200 && response.data) {
        this.currentUser = response.data.user;
        return true;
      }
      return false;
    } catch (err) {
      return false;
    }
  };

  @action
  public getMe = async () => {
    const jwt = localStorage.getItem("hugo_jwt")
    if(!jwt) {
      return false;
    }
    try {
      const response = await me();
      if (response.status === 200 && response.data) {
        this.isAuthenticated = true;
        this.currentUser = response.data as User;
        return true;
      }
    } catch (err) {
      return false;
    }
    return false;
  };

  @action
  public updateAddressForUser = async (address: CreateAddress) => {
    if (!this.currentUser) {
      return false;
    }
    const response = await updateAddress(address);
    if (response.status === 200) {
    }
  };

  @action
  public updateMe = async (user: any) => {
    try {
      const response = await updateUser(user);
      if (response.status === 200) {
        return true;
      }
    } catch (err) {
      return false;
    }
  };

  @action
  public logout = () => {
    localStorage.removeItem("hugo_jwt");
    this.isAuthenticated = false;
    return true;
  };

  @action
  public addPaymentToMe = async (paymentId: string) => {
    try {
      const response = await addPayment(paymentId);
      if (response.status === 200) {
      }
    } catch (err) {}
  };
}
