import React from "react";
import Loader from "../loader/Loader";
import "./LoadingScreen.scss";

const LoadingScreen = () => {
  return (
    <div className="loading-screen">
      <Loader size={48} />
    </div>
  );
};

export default LoadingScreen;
