import { inject, observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Cube from "../../../models/Cube.model";
import { CubeStore } from "../../../stores/cubes.store";
import TextHeaderBlock from "../../textheaderblock/TextHeaderBlock";
import ModalCard from "../ModalCard/ModalCard";
import forward from "../../../assets/forward.png";

import "./AddMyCube.scss";
import CardHeader from "../../cardheader/CardHeader";
import Indicator from "../../Indicator/Indicator";
import { toast } from "react-toastify";
import Loader from "../../loader/Loader";
import PrimaryButton from "../../primarybutton/PrimaryButton";
import MyCubeDetails from "../../mycubedetails/MyCubeDetails";
import { MyCubeStore } from "../../../stores/mycube.store";
import { ModalStore } from "../../../stores/modal.store";

interface AddMyCubeProps {
  cubeStore?: CubeStore;
  myCubeStore?: MyCubeStore;
  modalStore?: ModalStore;
}

const AddMyCube = ({ myCubeStore, cubeStore, modalStore }: AddMyCubeProps) => {
  const { t } = useTranslation(["onBoard"]);
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState(0);

  useEffect(() => {
    cubeStore!.loadAllCubes();
  }, [cubeStore]);

  const getCubeImage = (cube: Cube) => {
    if (cube.image && cube.image.formats && cube.image.formats.medium) {
      return cube.image.formats.medium.url;
    }
  };

  const generateAddress = (cube: Cube) => {
    const { address } = cube;
    return `${address.street}, ${address.zip} ${address.city} ${address.country}`;
  };

  const renderCubes = () => {
    return cubeStore!.cubes.map((cube: Cube, key: number) => {
      return (
        <div
          onClick={async () => {
            setLoading(true);
            if (await myCubeStore!.createMyCube(cube.id)) {
              setSelected(selected + 1);
              setLoading(false);
            } else {
              toast.error(t("errors:cubeAlreadyAdded"));
              setLoading(false);
            }
          }}
          key={key}
        >
          <div className="cube-card p-15">
            <CardHeader
              inverted={true}
              type=""
              image={getCubeImage(cube)}
              title={cube.description}
              subtitle={generateAddress(cube)}
            />
            <img src={forward} className="cube-card-img" alt="forward" />
          </div>
        </div>
      );
    });
  };

  const renderSlide0 = () => {
    return (
      <div className="add-my-cube-container">
        <div className="bottom-container mt-30">
          <TextHeaderBlock
            title={t("chooseHugoShort")}
            subtitle={t("letDeliverToHugo")}
            darkBackground={true}
            className="title"
          ></TextHeaderBlock>
          <div className="mt-30">{renderCubes()}</div>
        </div>
      </div>
    );
  };

  const renderSlide1 = () => {
    return (
      <div className="add-my-cube-container">
        <MyCubeDetails currentMyCube={myCubeStore!.currentMyCube} />
        <div className="button-container">
          <PrimaryButton
            className="finish-button"
            label={t("common:finish")}
            onClick={() => {
              modalStore!.closeModal();
              setSelected(0);
            }}
            inverted
          />
        </div>
      </div>
    );
  };
  if (loading) {
    return (
      <ModalCard>
        <div className="loader-container">
          <Loader size={50} />
        </div>
      </ModalCard>
    );
  }
  return (
    <ModalCard>
      <div className="add-my-cube">
        {selected === 0 && renderSlide0()}
        {selected === 1 && renderSlide1()}
        <Indicator numberOfDots={2} currentIndex={selected} className="pb-20" />
      </div>
    </ModalCard>
  );
};

export default inject(
  "cubeStore",
  "myCubeStore",
  "modalStore"
)(observer(AddMyCube));
