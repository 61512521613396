import { inject, observer } from "mobx-react";
import "./Modal.scss";
import React from "react";
import { ModalStore } from "../../stores/modal.store";
import AddMyCube from "./AddMyCube/AddMyCube";
import closeIcon from "../../assets/close.svg";
//@ts-ignore
import Rodal from "rodal";
import "rodal/lib/rodal.css";
import CubeDetails from "./cubedetails/CubeDetails";
import ShipmentDetail from "./ShipmentDetail/ShipmentDetail";
import { ShipmentStore } from "../../stores/shipment.store";
import { useHistory } from "react-router-dom";
import DeleteCube from "./DeleteCube/DeleteCube";
import ShareShipment from "./ShareShipment/ShareShipment";
import QrCodeModal from "./QrCodeModal/QrCodeModal";
import AddToBasket from "./AddToBasket/AddToBasket";

/**
 * Styles for modal
 */
let rodalStyle = {
  width: "100%",
  margin: 0,
  padding: 0,
  top: "unset",
  bottom: 0,
  height: "auto",
  background: "rgba(0,0,0,0)",
  boxShadow: "none",
  overflowY: "auto",
  WebkitOverflowScrolling: "touch",
};

/**
 * Routes for modal
 */
const routes: any = {
  addmycube: <AddMyCube />,
  deletecube: <DeleteCube />,
  shareshipment: <ShareShipment />,
  qrcode: <QrCodeModal />,
  addToBasket: <AddToBasket />,
};

interface ModalProps {
  modalStore?: ModalStore;
  shipmentStore?: ShipmentStore;
}

const Modal = ({ modalStore, shipmentStore }: ModalProps) => {
  let sliderModalBodyClass: string = "modal-body";
  let sliderAnimation: string = "slideUp";

  if (window.innerWidth <= 600) {
    rodalStyle = {
      ...rodalStyle,
      height: "100%",
    };
  }

  if (modalStore!.route === "shareshipment" && window.innerWidth <= 600) {
    return (
      <Rodal
        visible={modalStore!.isVisible}
        animation={sliderAnimation}
        onClose={() => {}}
        customStyles={rodalStyle}
        className={`modal-small`}
        showMask={false}
        showCloseButton={false}
      >
        <div className="content-modal">
          <div className="modal-body-small">
            <div
              className="modal-close"
              onClick={() => {
                modalStore!.closeModal();
              }}
            >
              <img src={closeIcon} alt="closeimg" />
            </div>
            {routes[modalStore!.route]}
          </div>
        </div>
      </Rodal>
    );
  }
  return (
    <Rodal
      visible={modalStore!.isVisible}
      animation={sliderAnimation}
      onClose={() => {}}
      customStyles={rodalStyle}
      className={`modal modal- ${modalStore!.route}`}
      showMask={false}
      showCloseButton={false}
    >
      <div
        className="modal-close"
        onClick={() => {
          modalStore!.closeModal();
        }}
      >
        <img src={closeIcon} alt="closeimg" />
      </div>
      <div className={sliderModalBodyClass}>{routes[modalStore!.route]}</div>
    </Rodal>
  );
};

export default inject("modalStore", "shipmentStore")(observer(Modal));
