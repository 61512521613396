import React, { Component } from "react";
import "./CheckboxToggle.scss";
import check from "../../assets/check.png";

interface CheckboxToggleProps {
  status: boolean;
}
/**
 * Checkbox component
 */
const CheckboxToggle = ({ status }: CheckboxToggleProps) => {
  return (
    <div className="checkbox-container">
      <div className="checkbox-background"></div>
      {status && <img src={check} className="checkbox-img" />}
    </div>
  );
};

export default CheckboxToggle;
