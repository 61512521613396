import axios from "axios";
import CreateUser from "../models/CreateUser.model";
import UserLogin from "../models/Login.model";

const BACKENDURL = process.env.REACT_APP_BACKEND_URL
  ? process.env.REACT_APP_BACKEND_URL
  : "https://api.hubtogo.com";

const login = (userLogin: UserLogin) => {
  return axios.post(BACKENDURL + "/auth/local", userLogin);
};

const register = (user: CreateUser) => {
  return axios.post(BACKENDURL + "/auth/local/register", user);
};

export { login, register };
