import { action, observable, makeObservable } from "mobx";
import { toast } from "react-toastify";
import Order, { OrderPart } from "../models/Order.model";
import ShopItem from "../models/Shopitem.model";
import {
  createOrder,
  getAllOrders,
  getOneOrder,
  getOneProducer,
  getShopItems,
} from "../services/HttpAuth.service";

export class ShopStore {
  @observable
  shopitems: ShopItem[] = [];

  @observable
  orderParts: OrderPart[] = [];

  @observable
  shopitem: ShopItem | undefined;

  @observable
  currentProducer: any;

  @observable
  allOrders: Order[] = [];

  @observable
  currentOrder: Order | undefined;

  constructor() {
    makeObservable(this);
  }

  @action
  public async loadAllShopitems() {
    const response = await getShopItems();
    if (response.status === 200) {
      this.shopitems = response.data;
    }
  }

  @action
  public async loadAllOrders() {
    console.log("CALLED????")
    const response = await getAllOrders();

    if (response.status === 200) {
      this.allOrders = response.data;
    }
  }

  @action
  public async createOrder() {
    const order = [];
    for (let orderpart of this.orderParts) {
      const part = {
        amount: orderpart.amount,
        shopitem: orderpart.shopitem.id,
      };
      order.push(part);
    }

    const resp = await createOrder(order);
    if (resp.status === 204) {
      localStorage.removeItem("orderparts");
      this.orderParts = [];
      toast.success("Die Bestellung wurde erfolgreich erstellt.");
    }
  }

  @action
  async findOne(id: number) {
    const response = await getOneOrder(id);
    if (response.status === 200) {
      this.currentOrder = response.data;
    }
  }

  @action
  async clearCurrentOrder() {
    this.currentOrder = undefined;
  }

  @action
  public findAndUpdateOrderPart(orderPartIn: OrderPart) {
    for (let orderpart of this.orderParts) {
      if (orderpart.shopitem.id === orderPartIn.shopitem.id) {
        orderpart.amount = orderPartIn.amount;
        this.orderParts = [...this.orderParts];
        localStorage.setItem("orderparts", JSON.stringify(this.orderParts));
      }
    }
  }

  @action
  public findAndDeleteOrderPart(orderPartIn: OrderPart) {
    let tmp: any = [];
    for (let orderpart of this.orderParts) {
      if (orderpart.shopitem.id !== orderPartIn.shopitem.id) {
        tmp.push(orderpart);
      }
    }
    this.orderParts = [...tmp];
    localStorage.setItem("orderparts", JSON.stringify(this.orderParts));
  }

  @action
  public addToShopCard(orderPart: OrderPart) {
    let foundPart: OrderPart | any = null;
    for (let part of this.orderParts) {
      if (part.shopitem.id === orderPart.shopitem.id) {
        foundPart = orderPart;
      }
    }

    if (!foundPart) {
      this.orderParts.push(orderPart);
      localStorage.setItem("orderparts", JSON.stringify(this.orderParts));

      return;
    }

    foundPart.amount += orderPart.amount;
    localStorage.setItem("orderparts", JSON.stringify(this.orderParts));
  }

  @action
  public setOrderParts(orderParts: OrderPart[]) {
    this.orderParts = orderParts;
  }

  @action
  public async setCurrentItem(shopitem: any) {
    this.shopitem = shopitem;
  }

  @action
  public async loadCurrentProducer(id: string) {
    const response = await getOneProducer(id);
    if (response.status === 200) {
      if (this.shopitem) {
        this.shopitem.producer = response.data;
      }
    }
  }
}
