import React, { useEffect, useState } from "react";
import "./Profile.scss";
import { useTranslation } from "react-i18next";
import Container from "../../components/container/Container";
import ProfileTabBar from "../../components/profiletabbar/ProfileTabBar";
import MyCubes from "./subpages/mycubes/MyCubes";
import UserProfile from "./subpages/userprofile/UserProfile";
import { inject, observer } from "mobx-react";
import { UserStore } from "../../stores/user.store";
import { MyCubeStore } from "../../stores/mycube.store";
import Credits from "../credits/Credits";

interface ProfileProps {
  userStore?: UserStore;
  myCubeStore?: MyCubeStore;
}

const Profile = ({ userStore, myCubeStore }: ProfileProps) => {
  const { t } = useTranslation(["profile"]);
  const [currentPage, setCurrentPage] = useState("mycubes");

  useEffect(() => {
    if (userStore) {
      userStore.getMe();
      myCubeStore!.getMyCubes();
    }
  }, []);

  return (
    <Container fullHeight>
      <div className="profile">
        <div className="title pl-20 pr-20">{t("profile")}</div>
        <ProfileTabBar
          currentPage={currentPage}
          onChange={(pathname: string) => {
            setCurrentPage(pathname);
          }}
        />
        <div className="content pl-20 pr-20">
          {currentPage === "mycubes" && <MyCubes />}
          {currentPage === "user" && <UserProfile />}
          {currentPage === "credits" && <Credits />}
        </div>
      </div>
    </Container>
  );
};

export default inject("userStore", "myCubeStore")(observer(Profile));
