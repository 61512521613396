import React, { useState } from "react";
import "./Register.scss";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";

import TextHeaderBlock from "../../components/textheaderblock/TextHeaderBlock";
import Container from "../../components/container/Container";
import hugoLogo from "../../assets/hugo_logo.png";
import TextInput from "../../components/TextInput/TextInput";
import CheckboxToggle from "../../components/checkboxtoggle/CheckboxToggle";
import PrimaryButton from "../../components/primarybutton/PrimaryButton";
import { inject, observer } from "mobx-react";
import { UserStore } from "../../stores/user.store";
import CreateUser from "../../models/CreateUser.model";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import LoginCheckPage from "../../components/LoginCheckPage/LoginCheckPage";
import FloatingBack from "../../components/floatingback/FloatingBack";
const EMAIL_REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

interface RegisterProps {
  userStore?: UserStore;
}

const Register = ({ userStore }: RegisterProps) => {
  const { t } = useTranslation(["register"]);
  const history = useHistory();
  const [agbChecked, setAgbChecked] = useState(false);
  const [loading, setLoading] = useState(false);
  const { register, handleSubmit, errors, setError } = useForm();

  const onSubmit = async (data: any) => {
    if (data.password != data.password2) {
      toast.error(t("errors:passwordsDidNotMatch"));
      setError("password", {
        type: "manual",
        message: "password not match",
      });
      setError("password2", {
        type: "manual",
        message: "password not match",
      });
      return;
    }
    if (agbChecked) {
      const user = data as CreateUser;
      setLoading(true);
      if (userStore && (await userStore.registerUser(user))) {
        setLoading(false);
        history.push("confirm");
        setLoading(false);
      } else {
        toast.error(t("errors:emailAlreadyInUse"));
        setLoading(false);
      }
    } else {
      toast.info(t("register:checkPrivacy"));
    }
  };

  const renderFormular = () => {
    return (
      <form onSubmit={handleSubmit(onSubmit)} className="form">
        <div className="side-by-side mb-10">
          <TextInput
            name="firstName"
            className="mr-5"
            placeholder={t("common:firstName")}
            value={""}
            error={errors["firstName"]}
            inputRef={register({
              required: true,
            })}
          />
          <TextInput
            name="lastName"
            className="ml-5"
            placeholder={t("common:lastName")}
            error={errors["lastName"]}
            value={""}
            inputRef={register({
              required: true,
            })}
          />
        </div>
        <TextInput
          placeholder={t("common:email")}
          name="email"
          error={errors["email"]}
          value={""}
          className="mb-10"
          inputRef={register({
            required: true,
            pattern: EMAIL_REGEX,
          })}
        />
        <TextInput
          placeholder={t("common:password")}
          name={"password"}
          type="password"
          error={errors["password"]}
          value={""}
          className="mb-10"
          inputRef={register({
            required: true,
          })}
        />
        <TextInput
          placeholder={t("common:confirm_password")}
          name={"password2"}
          type="password"
          error={errors["password2"]}
          value={""}
          className="mb-10"
          inputRef={register({
            required: true,
          })}
        />
        <div
          className="agb-container mt-30 mb-30"
          onClick={() => {
            setAgbChecked(!agbChecked);
          }}
        >
          <CheckboxToggle status={agbChecked} />
          <div className="agb">
            {t("conditions1")}{" "}
            <a
              href="https://www.servus.info/datenschutzhinweise/"
              target="_blank"
            >
              {t("dataPrivacyStatement")}
            </a>{" "}
            {t("conditions2")}{" "}
            <a href="https://www.servus.info/impressum/" target="_blank">
              {t("termsAndConditions")}
            </a>{" "}
            {t("conditions3")}
          </div>
        </div>

        <PrimaryButton
          inverted
          loading={loading}
          label={t("register")}
          submitButton
          onClick={() => {}}
        />
      </form>
    );
  };

  return (
    <LoginCheckPage>
      <Container>
        <FloatingBack inverted />
        <div className="register">
          <img src={hugoLogo} className="logo" />
          <TextHeaderBlock
            title={t("createAccount")}
            subtitle=""
            darkBackground
          />
          {renderFormular()}
        </div>
      </Container>
    </LoginCheckPage>
  );
};

export default inject("userStore")(observer(Register));
