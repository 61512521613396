import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import PrivateRoute from "./PrivateRoute";
//Pages
//public
import Login from "../pages/login/Login";
import Register from "../pages/register/Register";
import ForgotPassword from "../pages/forgotpassword/ForgotPassword";
import Welcome from "../pages/welcome/Welcome";
import ConfirmEmail from "../pages/confirmemail/ConfirmEmail";

//private
import Dashboard from "../pages/dashboard/Dashboard";
import Profile from "../pages/profile/Profile";
import Settings from "../pages/settings/Settings";
import Navbar from "../components/navbar/Navbar";
import Modal from "../components/Modal/Modal";
import Shop from "../pages/shop/Shop";
import CubeDetails from "../components/Modal/cubedetails/CubeDetails";
import ShipmentDetail from "../components/Modal/ShipmentDetail/ShipmentDetail";
import ShopCard from "../pages/shopcard/ShopCard";
import OrderDetail from "../components/orderdetail/OrderDetail";

const Navigator = () => {
  return (
    <>
      <Router>
        <Navbar />
        <Switch>
          <Route path="/" exact component={Welcome} />
          <Route path="/login" component={Login} />
          <Route path="/register" component={Register} />
          <Route path="/forgot" component={ForgotPassword} />
          <Route path="/confirm" component={ConfirmEmail} />

          <PrivateRoute path="/dashboard" component={Dashboard} />
          <PrivateRoute path="/profile" component={Profile} />
          <PrivateRoute path="/shop" component={Shop} />
          <PrivateRoute path="/cubedetails" component={CubeDetails} />
          <PrivateRoute path="/shipmentdetails" component={ShipmentDetail} />
          <PrivateRoute path="/orderdetails/:id" component={OrderDetail} />
          <PrivateRoute path="/settings" component={Settings} />
          <PrivateRoute path="/shopcard" component={ShopCard} />
        </Switch>
        <Modal />
      </Router>
    </>
  );
};

export default Navigator;
