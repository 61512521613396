import React, { useEffect, useState } from "react";
import "./Dashboard.scss";
import Container from "../../components/container/Container";
import TitleBar from "../../components/titlebar/Titlebar";
import TextHeaderBlock from "../../components/textheaderblock/TextHeaderBlock";
import noDeliveries from "../../assets/illustrations/undraw_on_the_way_ldaq.svg";
import { useTranslation } from "react-i18next";
import { inject, observer } from "mobx-react";
import { ShipmentStore } from "../../stores/shipment.store";
import ShipmentCard from "../../components/shipmentcard/ShipmentCard";
import LoadingScreen from "../../components/loadingscreen/LoadingScreen";
import { ModalStore } from "../../stores/modal.store";
import Invitation from "../../models/Invitations.model";
import Title from "../../components/title/Title";
import InvitationCard from "../../components/invitationcard/InvitationCard";
import { ShopStore } from "../../stores/shop.store";
import OrderCard from "../../components/OrderCard/OrderCard";

interface DashboardProps {
  shipmentStore?: ShipmentStore;
  shopStore?: ShopStore;
  modalStore?: ModalStore;
}

const Dashboard = ({
  shipmentStore,
  modalStore,
  shopStore,
}: DashboardProps) => {
  const { t } = useTranslation(["home"]);
  const [loading, setLoading] = useState(false);

  const [orders, setOrders] = useState([]);
  const [shipment, setShipment] = useState([]);

  const init = async () => {
    if (shipmentStore!.shipments.length == 0) {
      setLoading(true);
    }
    await shipmentStore!.loadInvitations();
    await shipmentStore!.loadAllShipments();
    await shopStore?.loadAllOrders();
    setLoading(false);
  };

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    if (shipmentStore?.shipments) {
      let itemsShipment: any = [];
      for (let shipment of shipmentStore?.shipments) {
        itemsShipment.push({
          type: "shipment",
          date: shipment.created_at,
          data: shipment,
        });
      }

      setShipment(itemsShipment);
    }
  }, [shipmentStore?.shipments]);

  useEffect(() => {
    let itemsOrder: any = [];

    if (shopStore?.allOrders) {
      for (let order of shopStore?.allOrders) {
        itemsOrder.push({
          type: "order",
          date: order.created_at,
          data: order,
        });
      }
    }

    setOrders(itemsOrder);
  }, [shopStore?.allOrders]);

  const renderEmptyDashboard = () => {
    return (
      <>
        <img className="header-image mt-30 mb-30" src={noDeliveries} />
        <TextHeaderBlock
          title={t("noPackage")}
          subtitle={t("weWillInformYou")}
        ></TextHeaderBlock>
      </>
    );
  };

  const renderShipment = (shipment: any) => {
    return <ShipmentCard shipment={shipment} className="mt-15" />;
  };

  const renderOrder = (order: any) => {
    return <OrderCard order={order} />;
  };

  const renderListOfInvites = () => {
    return shipmentStore!.invitations.map((invite: Invitation, key: number) => {
      return <InvitationCard invite={invite} key={key} />;
    });
  };

  const renderPlaceholder = () => {
    return (
      shipmentStore!.shipments.length == 0 &&
      shipmentStore!.invitations.length == 0
    );
  };

  const renderInvites = () => {
    if (shipmentStore!.invitations.length == 0) {
      return <></>;
    }
    return (
      <div className="invite">
        <Title title="Invitations" className="title" />
        {renderListOfInvites()}
      </div>
    );
  };

  const renderItems = () => {
    return [...shipment, ...orders]
      .sort(
        (a: any, b: any) =>
          new Date(b.date).getTime() - new Date(a.date).getTime()
      )
      .map((item: any, key) => {
        return (
          <div key={key}>
            {item.type == "shipment" && renderShipment(item.data)}
            {item.type == "order" && renderOrder(item.data)}
          </div>
        );
      });
  };

  const renderContent = () => {
    return (
      <div className="dashboard">
        <TitleBar />
        {!renderPlaceholder() && renderInvites()}
        {renderPlaceholder() && renderEmptyDashboard()}

        {shipmentStore!.shipments.length > 0 && renderItems()}
        <div style={{ height: 180 }}></div>
      </div>
    );
  };

  if (loading) {
    return <LoadingScreen />;
  }

  return <Container fullHeight>{renderContent()}</Container>;
};

export default inject(
  "shipmentStore",
  "modalStore",
  "shopStore"
)(observer(Dashboard));
