import React from "react";
import "./Indicator.scss";

interface IndicatorProps {
  numberOfDots: number;
  currentIndex: number;
  className:any;
}
const Indicator = ({ numberOfDots, currentIndex,className }: IndicatorProps) => {
  const renderDots = () => {
    return Array(numberOfDots)
      .fill(true)
      .map((el, index) => {
        return (
          <div
            key={index}
            className={index === currentIndex ? "dot-active" : "dot"}
          ></div>
        );
      });
  };
  return <div className={`indicator ${className}`}>{renderDots()}</div>;
};

export default Indicator;
