import React from "react";
import "./Backheader.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/pro-solid-svg-icons";
import { useHistory } from "react-router-dom";

const BackHeader = ({ title }: { title?: string }) => {
  const history = useHistory();
  return (
    <div className="back-header">
      <div
        className="bubble"
        onClick={() => {
          history.goBack();
        }}
      >
        <FontAwesomeIcon icon={faArrowLeft} color={"#000"} />
      </div>
      <div className="text">{title}</div>
    </div>
  );
};

export default BackHeader;
