import React from "react";
import "./Welcome.scss";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import Container from "../../components/container/Container";
import PrimaryButton from "../../components/primarybutton/PrimaryButton";
import TextHeaderBlock from "../../components/textheaderblock/TextHeaderBlock";
import MaxWidthDiv from "../../components/MaxWidthDiv/MaxWidthDiv";

import headerImage from "../../assets/illustrations/undraw_directions_x53j.svg";
import hugoLogo from '../../assets/hugo_logo.png';
import LoginCheckPage from "../../components/LoginCheckPage/LoginCheckPage";

const Welcome = () => {
  const { t } = useTranslation(["welcome"]);
  const history = useHistory();
  return (
    <LoginCheckPage>
      <Container>
        <div className="welcome">
          <img src={hugoLogo} className="logo" />
          <TextHeaderBlock
            title={t("welcome")!}
            subtitle={t("greeting")}
            darkBackground
          />
          <MaxWidthDiv large className="mt-15">
            <PrimaryButton
              label={t("createAccount")}
              inverted
              onClick={() => {
                history.push("register");
              }}
            />
            <div className="seperator"></div>
            <PrimaryButton
              label={t("common:login")}
              className="mt-15"
              inverted
              onClick={() => {
                history.push("login");
              }}
            />
          </MaxWidthDiv>
        </div>
      </Container>
    </LoginCheckPage>
  );
};

export default Welcome;
