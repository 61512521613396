import React, { useEffect, useState } from "react";
import "./ShipmentCard.scss";
import Shipment from "../../models/Shipment.model";
import { ShipmentStore } from "../../stores/shipment.store";
import Card from "../card/Card";
import CardHeader from "../cardheader/CardHeader";
import TimeAgoHelper from "../../services/TimeAgoHelper";
import { inject } from "mobx-react";
import { ModalStore } from "../../stores/modal.store";
import { useHistory } from "react-router-dom";
import checkedVector from "../../assets/icons/checked.svg";
import CubeAddress from "../cubeaddress/CubeAddress";
import { useTranslation } from "react-i18next";
import editIcon from "../../assets/icons/edit.svg";
import closeIcon from "../../assets/icons/close_black.svg";
import PrimaryButton from "../primarybutton/PrimaryButton";
import TextInput from "../TextInput/TextInput";

interface ShipmentCardProps {
  shipment: Shipment;
  notClickable?: boolean;
  fromDetail?: boolean;
  className?: any;
  shipmentStore?: ShipmentStore;
  modalStore?: ModalStore;
  maxed?: boolean;
  enableEdit?: boolean;
}

const ShipmentCard = ({
  shipment,
  notClickable,
  className,
  fromDetail,
  maxed,
  shipmentStore,
  enableEdit,
}: ShipmentCardProps) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [description, setDescription] = useState("");
  const [editModeOn, setEditModeOn] = useState(false);
  const [shipmentId, setShipmentId] = useState("");
  const [loading, setLoading] = useState(false);

  let title = shipment.cubeid
    ? shipment.cubeid.description
    : t("common:shipment");
  let subtitle = TimeAgoHelper.format(new Date(shipment.updated_at));
  const profile = fromDetail ? "from=profile" : "";

  useEffect(() => {
    if (shipment && shipment.description) {
      setDescription(shipment.description);
    }
  }, []);

  useEffect(() => {
    const getUrlParameter = (name: string) => {
      name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
      let regex = new RegExp("[\\?&]" + name + "=([^&#]*)");
      let results = regex.exec(window.location.search);
      return results === null
        ? ""
        : decodeURIComponent(results[1].replace(/\+/g, " "));
    };
    const key = getUrlParameter("shipmentid");
    setShipmentId(key);
  }, []);

  const renderDescription = () => {
    if (!enableEdit) {
      return (
        <div className="mt-15 shipment-card-description-container">
          {shipment.description}
        </div>
      );
    }
    if (editModeOn) {
      return (
        <div className="mt-15 shipment-card-description-container">
          <TextInput
            onChange={(text) => {
              setDescription(text);
            }}
            placeholder="Description"
            value={description}
            className="text"
          />
          <div className="edit-bar">
            <div
              className="edit-bar"
              onClick={() => {
                setEditModeOn(false);
              }}
            >
              <img src={closeIcon} />
            </div>
            <PrimaryButton
              className="save"
              loading={loading}
              onClick={() => {
                const update = async () => {
                  await shipmentStore?.updateShipmentDescription(
                    shipment.id,
                    shipmentId,
                    description
                  );
                  setEditModeOn(false);
                  setLoading(false);
                };
                setLoading(true);
                update();
              }}
              label="Save"
            />
          </div>
        </div>
      );
    }
    return (
      <div className="mt-15 shipment-card-description-container">
        {shipment.description ? shipment.description : "Add description"}
        <div
          onClick={() => {
            setEditModeOn(!editModeOn);
          }}
        >
          <img src={editIcon} />
        </div>
      </div>
    );
  };

  const renderContent = () => (
    <Card className={className}>
      <CardHeader
        type="cube"
        title={title}
        subtitle={subtitle}
        done={shipment.received}
        image={shipment.received ? checkedVector : shipment.containerimage}
      >
        {shipment.cubeid && maxed && (
          <div className="mt-15">
            <CubeAddress cube={shipment.cubeid} invert />
          </div>
        )}
        {renderDescription()}
      </CardHeader>
    </Card>
  );

  if (notClickable) {
    return <div>{renderContent()}</div>;
  }

  return (
    <div
      className="clickable"
      onClick={() => {
        history.push(
          "shipmentdetails?shipmentid=" + shipment.sharetoken + "&" + profile
        );
      }}
    >
      {renderContent()}
    </div>
  );
};

export default inject("modalStore", "shipmentStore")(ShipmentCard);
