import React from "react";
import LoadingAnimation from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

interface LoaderProps {
  inverted?: boolean;
  size?: number;
}

const Loader = ({ inverted, size }: LoaderProps) => {
  return (
    <LoadingAnimation
      type="TailSpin"
      color={inverted ? "#269ab2" : "#269ab2"}
      height={size ? size : 15}
      width={size ? size : 15}
    />
  );
};

export default Loader;
