import React, { Component } from "react";
import "./TextHeaderBlock.scss";
import classNames from "classnames";

interface TextHeaderBlockProps {
  title: string;
  subtitle?: string;
  darkBackground?: boolean;
  className?: any;
  subtitleClassName?: any;
}
/**
 * Header text component
 */
const TextHeaderBlock = ({
  title,
  subtitle,
  darkBackground,
  className,
  subtitleClassName,
}: TextHeaderBlockProps) => {
  const textheaderStyle = classNames({
    "text-header-block": true,
    "text-header-block--dark": darkBackground,
  });
  return (
    <div className={`${textheaderStyle} ${className}`}>
      {title && title.length > 0 && <div className="title">{title}</div>}
      {subtitle && subtitle.length > 0 && (
        <div className={`subtitle ${subtitleClassName}`}>{subtitle}</div>
      )}
    </div>
  );
};

export default TextHeaderBlock;
