import React, { useEffect } from "react";
import "./Shop.scss";
import Container from "../../components/container/Container";
import { inject, observer } from "mobx-react";
import { ShopStore } from "../../stores/shop.store";
import ShopItemCard from "../../components/shopitem/ShopItemCard";
import ShopCardButton from "../../components/shopcardbutton/ShopCardButton";
import Title from "../../components/title/Title";
import ShopHeader from "../../components/ShopHeader/ShopHeader";
interface ShopProps {
  shopStore?: ShopStore;
}

const Shop = ({ shopStore }: ShopProps) => {
  useEffect(() => {
    shopStore?.loadAllShopitems();
  }, []);

  const renderItems = () => {
    return shopStore?.shopitems.map((item, key) => {
      return <ShopItemCard item={item} key={key} />;
    });
  };

  return (
    <Container fullHeight>
      <ShopHeader />
      <div className="shop">{renderItems()}</div>
      <div style={{height: 80}}></div>
    </Container>
  );
};

export default inject("shopStore")(observer(Shop));
