import { inject, observer } from "mobx-react";
import "./AddToBasket.scss";
import React, { useEffect, useState } from "react";
import { ShopStore } from "../../../stores/shop.store";
import Title from "../../title/Title";
import PrimaryButton from "../../primarybutton/PrimaryButton";
import TextInput from "../../TextInput/TextInput";
import { ModalStore } from "../../../stores/modal.store";
import PriceDetails from "../../pricedetails/PriceDetails";
interface AddToBasketProps {
  shopStore?: ShopStore;
  modalStore?: ModalStore;
}

const AddToBasket = ({ shopStore, modalStore }: AddToBasketProps) => {
  const currentItem = shopStore?.shopitem;
  const [amount, setAmount] = useState(1);

  useEffect(() => {
    setAmount(1);
  }, [modalStore?.isVisible]);

  if (!currentItem) {
    return <></>;
  }

  return (
    <div className="add-to-basket-container">
      <img src={currentItem.image.url} />
      <div className="content">
        <Title title={currentItem.title} className="title" />
        <div className="mt-10">{currentItem.shortDescription}</div>
        {currentItem.producer && (
          <>
            <div className="subtitle mb-10">Lieferzeitpunkt</div>
            <div>
              {currentItem.producer.lieferInformation
                ? currentItem.producer.lieferInformation
                : ""}
            </div>
          </>
        )}
        <div className="subtitle mb-10">Beschreibung</div>
        <div>{currentItem.description}</div>
        {currentItem.producer && (
          <div className="subtitle">
            Hergestellt von {currentItem.producer.name}
          </div>
        )}
        <div style={{ height: 120 }}></div>
        <div className="footer">
          <div className="left">
            <PriceDetails price={currentItem.price.toFixed(2)} />
          </div>
          <PrimaryButton
            label="In den Warenkorb"
            onClick={() => {
              shopStore?.addToShopCard({
                amount,
                shopitem: currentItem,
              });
              modalStore?.closeModal();
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default inject("shopStore", "modalStore")(observer(AddToBasket));
