import { action, observable, makeObservable } from "mobx";
import MyCube from "../models/MyCube.model";
import {
  createCubeForMe,
  deleteCubeForMe,
  getMyCubeById,
  getAllMyCubes,
} from "../services/HttpAuth.service";

export class MyCubeStore {
  @observable
  myCubes: MyCube[] = [];

  @observable
  currentMyCube: MyCube | undefined;

  constructor() {
    makeObservable(this);
  }

  @action
  async getMyCubes() {
    try {
      const response = await getAllMyCubes();
      if (response.status === 200) {
        this.myCubes = response.data;
        return true;
      }
    } catch (err) {}
    return false;
  }

  @action
  async createMyCube(id: number) {
    try {
      const response = await createCubeForMe(id);
      if (response.status === 200) {
        this.currentMyCube = response.data;
        this.getMyCubes();
        return true;
      }
    } catch (err) {}
    return false;
  }

  @action
  async deleteCurrentMyCube() {
    if (this.currentMyCube) {
      try {
        const response = await deleteCubeForMe(this.currentMyCube.id);
        if (response.status === 202) {
          this.myCubes = response.data;
          this.currentMyCube = undefined;
          return true;
        }
      } catch (err) {}
      return false;
    }
  }

  @action
  async setCurrentMyCube(myCube: MyCube) {
    this.currentMyCube = myCube;
  }

  @action
  async getMyCubeById(id: number) {
    const myCube = this.myCubes.find((myCube) => id == myCube.cube.id);
    this.currentMyCube = myCube;
    if (!this.currentMyCube) {
      try {
        const response = await getMyCubeById(id);
        this.currentMyCube = response.data;
      } catch (err) {}
    }
  }
}
