import { inject } from "mobx-react";
import "./QrCodeModal.scss";
import React from "react";
import QRCode from "qrcode.react";
import { useTranslation } from "react-i18next";
import { ShipmentStore } from "../../../stores/shipment.store";
import Container from "../../container/Container";

const QrCodeModal = ({ shipmentStore }: { shipmentStore?: ShipmentStore }) => {
  const { t } = useTranslation();

  if (!shipmentStore!.currentShipment) {
    return <></>;
  }
  return (
    <Container>
      <div className="qr-code-detail">
        <div className="qr-code-title"></div>
        <QRCode
          bgColor="transparent"
          fgColor="#000000"
          value={shipmentStore!.currentShipment.confirmationcode}
          size={window.innerWidth <= 600 ? window.innerWidth * 0.8 : 250}
        />
      </div>
    </Container>
  );
};

export default inject("shipmentStore")(QrCodeModal);
