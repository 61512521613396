import React from "react";
import "./Credits.scss";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import PrimaryButton from "../../components/primarybutton/PrimaryButton";
import Card from "../../components/card/Card";
import Title from "../../components/title/Title";
import { useTranslation } from "react-i18next";
import { inject } from "mobx-react";
import { UserStore } from "../../stores/user.store";

interface UserStoreProps {
  userStore?: UserStore;
}

const Credits = ({ userStore }: UserStoreProps) => {
  const { t } = useTranslation("credit");
  const stripe = useStripe();
  const elements = useElements();
  return <></>;
  const handleSubmit = async (event: any) => {
    // Block native form submission.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    // Get a reference to a mounted CardElement. Elements knows how
    // to find your CardElement because there can only ever be one of
    // each type of element.
    const cardElement = elements.getElement(CardElement);

    // Use your card Element with other Stripe.js APIs
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: cardElement!,
    });

    userStore?.addPaymentToMe(paymentMethod!.id);

    if (error) {
      console.log("[error]", error);
    } else {
      console.log("[PaymentMethod]", paymentMethod);
    }
  };
  return (
    <form onSubmit={handleSubmit} className="credits">
      <Title title={t("add_credit")} className="mb-15" />
      <Card>
        <CardElement />
      </Card>
      <div className="btn-container">
        <PrimaryButton
          label={t("add_credit_button")}
          onClick={() => {}}
          submitButton
          className="add-payment"
        />
      </div>
    </form>
  );
};

export default inject("userStore")(Credits);
