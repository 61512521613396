import React from "react";
import "./FloatingBack.scss";
import { useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/pro-solid-svg-icons";

const FloatingBack = ({
  inverted,
  onClick,
  title,
}: {
  inverted?: boolean;
  onClick?: Function;
  title?: string;
}) => {
  const history = useHistory();
  return (
    <>
      <div
        className={inverted ? "floating-inverted" : "floating-back"}
        onClick={() => {
          if (onClick) {
            onClick();
          }
          history.goBack();
        }}
      >
        <FontAwesomeIcon
          icon={faArrowLeft}
          color={inverted ? "#fff" : "#000"}
        />
      </div>
      <div>{title}</div>
    </>
  );
};

export default FloatingBack;
