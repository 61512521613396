import { action, observable, makeObservable } from "mobx";
import Invitation from "../models/Invitations.model";
import Shipment from "../models/Shipment.model";
import {
  loadShipments,
  loadShipmentsByShareToken,
  loadInvitations,
  redeemInvitation,
  cancleInvitation,
  invitePerEmail,
  updateShipmentById,
} from "../services/HttpAuth.service";

export class ShipmentStore {
  @observable
  shipments: Shipment[] = [];

  @observable
  currentShipment: Shipment | undefined;

  @observable
  isAuthenticated: boolean = false;

  @observable
  invitations: Invitation[] = [];

  constructor() {
    makeObservable(this);
  }

  @action
  public async loadAllShipments() {
    try {
      const response = await loadShipments();
      this.shipments = response.data as Shipment[];
    } catch (err) {
      console.log(err);
      return false;
    }
  }
  @action
  public loadShipmentsForCube(cubeid: number) {
    return this.shipments.filter((shipment) => shipment.cubeid.id == cubeid);
  }

  @action
  public setMyShipment(shipment: Shipment) {
    this.currentShipment = shipment;
  }

  @action
  public async setShipmentByShareToken(token: string) {
    try {
      const response = await loadShipmentsByShareToken(token);
      if (response.status === 200) {
        this.currentShipment = response.data;
      }
    } catch (err) {
      return false;
    }
  }

  @action
  public async loadInvitations() {
    try {
      const response = await loadInvitations();
      if (response.status === 200) {
        this.invitations = response.data;
      }
    } catch (err) {
      return false;
    }
  }

  @action
  public async redeemInvitation(invitation: Invitation) {
    try {
      const response = await redeemInvitation(invitation);
      if (response.status === 200) {
        await this.loadInvitations();
        await this.loadAllShipments();
      }
    } catch (err) {
      return false;
    }
  }

  @action async cancleInvitation(invitation: Invitation) {
    try {
      const response = await cancleInvitation(invitation);
      if (response.status === 200) {
        await this.loadInvitations();
        await this.loadAllShipments();
      }
    } catch (err) {
      return false;
    }
  }

  @action
  async invitePerEmail(email: string, shipmentid: number) {
    try {
      const response = await invitePerEmail(email, shipmentid);
      if (response.status === 200) {
        return true;
      }
    } catch (err) {
      return false;
    }
  }

  @action
  async updateShipmentDescription(
    shipmentId: number,
    shareToken: string,
    description: string
  ) {
    try {
      const response = await updateShipmentById(shipmentId, { description });
      if (response.status === 200) {
        await this.setShipmentByShareToken(shareToken);
        return true;
      }
    } catch (err) {
      return false;
    }
  }
}
