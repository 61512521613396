import TimeAgo from 'javascript-time-ago'
import de from 'javascript-time-ago/locale/de'
import en from 'javascript-time-ago/locale/en'

import {getI18n} from 'react-i18next'
const i18n = getI18n();

TimeAgo.addLocale(de as any);
TimeAgo.addLocale(en as any);

export default class TimeAgoHelper {
    public static format(date: Date) {
        const timeAgo = new TimeAgo(i18n.language);
        return timeAgo.format(date);
    }
}