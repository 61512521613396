import React from "react";
import "./Title.scss";

interface TitleProps {
  title: string;
  className?: any;
}

const Title = ({ title, className }: TitleProps) => {
  return <div className={`custom-title ${className}`}>{title}</div>;
};

export default Title;
