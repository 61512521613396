import React from "react";
import "./CubeAddress.scss";
//@ts-ignore
import GoogleMapReact from "google-map-react";
import marker from "../../assets/marker.svg";
import Cube from "../../models/Cube.model";
import { inject } from "mobx-react";
import { UserStore } from "../../stores/user.store";

const CUBE = (lat: any, lng: any) => (
  <div style={{ position: "relative" }}>
    <img
      src={marker}
      style={{
        width: 28,
        height: 28,
        position: "absolute",
        top: -14,
        left: -14,
      }}
    />
  </div>
);

interface CubeAddressProps {
  cube: Cube;
  showNameAndAddress?: boolean;
  invert?: boolean;
  notCLickable?: boolean;
  shipmentcode?: string;
  userStore?: UserStore;
}

const CubeAddress = ({
  cube,
  invert,
  userStore,
  shipmentcode,
  showNameAndAddress,
}: CubeAddressProps) => {
  const renderAddress = () => {
    const { address } = cube;
    if (!address) {
      return <></>;
    }
    const currentUser = userStore!.currentUser;
    return (
      <div className="address">
        <div className={invert ? "address-text-invert" : "address-text"}>
          {showNameAndAddress && (
            <div>{currentUser?.lastName + " " + currentUser?.firstName}</div>
          )}
          <div>{address.street}</div>
          {showNameAndAddress && (
            <div className="co-code">
              {cube.description + " c/o " + shipmentcode}
            </div>
          )}
          <div>
            {address.zip} {address.city}
          </div>
          <div>{address.country}</div>
        </div>
        <div
          className="map"
          onClick={() => {
            window.open(`https://maps.google.com?q=47.434121, 9.746930`);
          }}
        >
          <GoogleMapReact
            bootstrapURLKeys={{
              key: "AIzaSyBwLcTvdQHYY03aJAhpNb-FwH13HnJ4UZc",
            }}
            defaultCenter={{
              lat: address.lat ? parseFloat(address.lat) : 0,
              lng: address.lng ? parseFloat(address.lng) : 0,
            }}
            defaultZoom={13}
            options={{
              streetViewControl: false,
              scaleControl: false,
              mapTypeControl: false,
              panControl: false,
              zoomControl: false,
              rotateControl: false,
              fullscreenControl: false,
            }}
            style={{
              width: "100%",
              height: "100%",
              margin: 0,
              padding: 0,
              position: "relative",
              borderTopLeftRadius: 15,
              borderTopRightRadius: 15,
            }}
          >
            <CUBE lat={address.lat} lng={address.lng} />
          </GoogleMapReact>
        </div>
      </div>
    );
  };
  return <> {renderAddress()}</>;
};

export default inject("userStore")(CubeAddress);
