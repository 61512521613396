import React, { Component } from "react";
import "./CardHeader.scss";

import cubeVector from "../../assets/icons/cube.svg";
import {
  faShoppingCart,
  faCheck,
  faCube,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface CardHeaderProps {
  type: string;
  title: string;
  subtitle: any;
  image?: string;
  done?: boolean;
  inverted?: boolean;
  children?: any;
}

const CardHeader = ({
  type,
  image,
  done,
  inverted,
  title,
  subtitle,
  children,
}: CardHeaderProps) => {
  let cubeImage: any = faCube;
  if (type == "order") {
    cubeImage = faShoppingCart;
  }
  if (done) {
    cubeImage = faCheck;
  }
  return (
    <div>
      <div className="card-header">
        <div
          className={
            inverted
              ? "card-header-item-symbol-inverted"
              : "card-header-item-symbol"
          }
        >
          <FontAwesomeIcon icon={cubeImage} color="#fff" />
        </div>
        <div
          className={
            inverted
              ? "card-header-item-text-inverted"
              : "card-header-item-text"
          }
        >
          <div className="card-header-item-title">{title}</div>
          <div className="card-header-item-subtitle">{subtitle}</div>
        </div>
      </div>
      {children}
    </div>
  );
};

export default CardHeader;
