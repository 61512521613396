import { inject, observer } from "mobx-react";
import "./UserProfile.scss";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import PrimaryButton from "../../../../components/primarybutton/PrimaryButton";
import TextInput from "../../../../components/TextInput/TextInput";
import Title from "../../../../components/title/Title";
import { UserStore } from "../../../../stores/user.store";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";

interface UserProfileProps {
  userStore?: UserStore;
}

const UserProfile = ({ userStore }: UserProfileProps) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { register, handleSubmit, errors, setError } = useForm();
  const [loading, setLoading] = useState(false);

  const onSubmit = async (data: any) => {
    if (
      (data.password != "" || data.password2 != "") &&
      data.password != data.password2
    ) {
      toast.error(t("errors:passwordsDidNotMatch"));
      setError("password", {
        type: "manual",
        message: "password not match",
      });
      setError("password2", {
        type: "manual",
        message: "password not match",
      });
      return;
    }
    if (userStore) {
      setLoading(true);
      if (await userStore.updateMe(data)) {
        toast.success(t("profile:userDataSuccessfullyUpdated"));
      } else {
        toast.error(t("errors:somethingWentWrong"));
      }
      setLoading(false);
    }
  };

  const user = userStore?.currentUser;
  if (!user) {
    return <></>;
  }

  return (
    <div className="user-profile mt-30">
      <Title title={t("common:userTab")} />
      <form onSubmit={handleSubmit(onSubmit)}>
        <TextInput
          name="firstName"
          className="mt-15"
          placeholder={t("common:firstName")}
          error={errors["firstName"]}
          value={user.firstName}
          inputRef={register({
            required: true,
          })}
        />
        <TextInput
          name="lastName"
          className="mt-15"
          error={errors["lastName"]}
          placeholder={t("common:lastName")}
          value={user.lastName}
          inputRef={register({
            required: true,
          })}
        />
        <TextInput
          name="email"
          className="mt-15"
          placeholder={t("common:email")}
          error={errors["email"]}
          readOnly
          value={user.email}
          inputRef={register({
            required: true,
          })}
        />
        <Title title="Password" className="mt-15" />
        <TextInput
          name="password"
          className="mt-15"
          placeholder={t("profile:oldPassword")}
          error={errors["password"]}
          value={""}
          type="password"
          inputRef={register()}
        />
        <TextInput
          name="password2"
          className="mt-15"
          placeholder={t("profile:newPassword")}
          error={errors["password2"]}
          value={""}
          type="password"
          inputRef={register()}
        />
        <PrimaryButton
          label={t("common:save")}
          submitButton
          loading={loading}
          className="mt-30"
          onClick={() => {}}
        />
      </form>
      <div
        className="mt-30 logout"
        onClick={() => {
          if (userStore!.logout()) {
            history.push("/");
          }
        }}
      >
        {t("common:logout")}
      </div>
    </div>
  );
};

export default inject("userStore")(observer(UserProfile));
