import React from "react";
import classNames from "classnames";
import "./TextInput.scss";

interface TextInputProps {
  placeholder: string;
  inputRef?: any;
  value: any;
  type?: any;
  className?: any;
  error?: string;
  onEnter?: any;
  name?: string;
  label?: string;
  required?: boolean;
  readOnly?: boolean;
  edit?: any;
  onChange?: (text: any) => void;
}

export const TextInput = ({
  placeholder,
  value,
  type,
  className,
  onEnter,
  error,
  inputRef,
  name,
  label,
  required,
  readOnly,
  edit,
  onChange,
}: TextInputProps) => {
  const onEnterPressed = (event: any) => {
    if (event.key === "Enter" && onEnter) {
      onEnter();
    }
  };

  const fieldType = () => {
    if (type && type === "password") {
      return "password";
    }
    if (type && type === "email") {
      return "email";
    }
    return "text";
  };

  const renderEditableInput = () => {
    const editableClassName = classNames({
      "textinput-gray": true,
      "textinput-error": error,
      "read-only": readOnly,
    });

    return (
      <div className="input-wrapper">
        <input
          readOnly={readOnly}
          ref={inputRef}
          className={editableClassName}
          type={fieldType()}
          name={name}
          autoComplete="off"
          placeholder={placeholder}
          defaultValue={value}
          onChange={(text) => {
            if (onChange) {
              onChange(text.target.value);
            }
          }}
        />
        {edit && (
          <div className="edit" onClick={edit}>
            Edit
          </div>
        )}
      </div>
    );
  };

  const textInputClassName = classNames(
    {
      "field-container": true,
    },
    className
  );

  return (
    <div
      className={textInputClassName}
      onClick={() => {}}
      onKeyPress={(event) => {
        onEnterPressed(event);
      }}
    >
      {label && (
        <div className="input-label">
          {label} {required && <span className="input-required">*</span>}
        </div>
      )}
      {renderEditableInput()}
    </div>
  );
};

export default TextInput;
