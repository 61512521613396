import { inject, observer } from "mobx-react";
import "./MyCubes.scss";
import React, { useEffect, useState } from "react";

import MyCubeCard from "../../../../components/mycubecard/MyCubeCard";
import MyCube from "../../../../models/MyCube.model";
import { MyCubeStore } from "../../../../stores/mycube.store";

const settings = {
  height: "auto",
  variableWidth: true,
  padding: 0,
  dots: false,
  fade: false,
  infinite: false,
  slidesToScroll: 1,
  slidesToShow: 1,
  speed: 500,
  swipeToSlide: true,
  arrows: false,
};

interface MyCubesProps {
  myCubeStore?: MyCubeStore;
}

const MyCubes = ({ myCubeStore }: MyCubesProps) => {
  const [numberOfCubes, setNumberOfCubes] = useState(0);

  useEffect(() => {
    if (myCubeStore) {
      myCubeStore.getMyCubes();
    }
  }, []);

  useEffect(() => {
    if (myCubeStore?.myCubes) {
      setNumberOfCubes(myCubeStore?.myCubes.length);
    }
  }, [myCubeStore?.myCubes]);

  const renderMyCubesList = () => {
    return myCubeStore?.myCubes.map((cube: MyCube, key: number) => {
      return (
        <MyCubeCard
          myCube={cube}
          key={key}
          className={key % 2 == 0 ? "mr-5" : "ml-5"}
        />
      );
    });
  };

  return (
    <div className="my-cubes">
      {renderMyCubesList()}
      <MyCubeCard isAdd className={numberOfCubes % 2 ? "ml-5" : "mr-5"} />
    </div>
  );
};

export default inject("myCubeStore")(observer(MyCubes));
