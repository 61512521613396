import React, { useEffect, useState } from "react";
import "./OrderCard.scss";
import Order, { OrderPart } from "../../models/Order.model";
import Card from "../card/Card";
import CardHeader from "../cardheader/CardHeader";
import shop from "../../assets/shopping_card.svg";
import { useHistory } from "react-router-dom";
import ShoppingCard from "../../pages/ShoppingCardItem.tsx/ShoppingCard";
import TimeAgoHelper from "../../services/TimeAgoHelper";

interface OrderCardInterface {
  order: Order;
  flat?: boolean;
  displayChild?: boolean;
}

const OrderCard = ({ order, displayChild, flat }: OrderCardInterface) => {
  const history = useHistory();

  const [totalPrice, setTotalPrice] = useState(0);

  useEffect(() => {
    calculatePrice();
  }, [order]);

  const generateSub = () => {
    let subtitle = "";
    if (order.orderpart) {
      for (let i = 0; i < order.orderpart.length; i++) {
        let part = order.orderpart[i];
        subtitle += part.shopitem.title;
        if (i + 1 < order.orderpart.length) {
          subtitle += ", ";
        }
      }
    }
    return subtitle;
  };

  const calculatePrice = () => {
    let price = 0;
    const orderparts = order.orderpart;
    if (orderparts && orderparts.length > 0) {
      for (let part of orderparts) {
        price += part.amount * part.shopitem.price;
      }
      setTotalPrice(price)
    }
  };

  const renderItems = () => {
    return order.orderpart.map((orderPart: OrderPart, key: number) => {
      return (
        <div key={key}>
          <ShoppingCard orderPart={orderPart} flat hideAddSub />
        </div>
      );
    });
  };

  const renderChild = () => {
    if (!displayChild) {
      return <></>;
    }
    return (
      <div className="order-overview">
        {renderItems()}
        <div className="price-bottom">
          <div>Gesamtpreis</div>
          <div>€ {totalPrice.toFixed(2)}</div>
        </div>
      </div>
    );
  };

  return (
    <div
      onClick={() => {
        history.push("/orderdetails/" + order.id);
      }}
    >
      <Card className="order-card mt-15">
        <CardHeader
          type="order"
          title={"Bestellung"}
          subtitle={generateSub()}
          done={order.status === 'COLLECTED'}
          image={shop}
          children={renderChild()}
        />
      </Card>
    </div>
  );
};

export default OrderCard;
