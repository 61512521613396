import { inject } from "mobx-react";
import "./InvitaitonCard.scss";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Invitation from "../../models/Invitations.model";
import TimeAgoHelper from "../../services/TimeAgoHelper";
import { ShipmentStore } from "../../stores/shipment.store";
import Card from "../card/Card";
import CardHeader from "../cardheader/CardHeader";

import checkIcons from "../../assets/icons/checked.svg";
import closeIcon from "../../assets/icons/close.svg";
import Loader from "../loader/Loader";

interface InvitationCardProps {
  invite: Invitation;
  shipmentStore?: ShipmentStore;
}
const InvitationCard = ({ invite, shipmentStore }: InvitationCardProps) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const shipment = invite.shipment;
  let title = shipment.cubeid ? shipment.cubeid.description : t('common:shipment');
  let subtitle = TimeAgoHelper.format(new Date(shipment.updated_at));

  if (loading) {
    return (
      <Card className="loader">
        <Loader size={32} />
      </Card>
    );
  }
  const generateUsername = () => {
    if (invite.user_from) {
      return invite.user_from.firstName + " " + invite.user_from.lastName;
    }
    return "HuGouser";
  };

  if (loading) {
    <Card>
      <Loader size={32} />
    </Card>;
  }
  return (
    <Card>
      <CardHeader
        type="cube"
        title={title}
        subtitle={subtitle}
        done={shipment.received}
        image={shipment.received ? undefined : shipment.containerimage}
      />
      <div className="invite-body">
        {t("common:invite")}
        {generateUsername() + t("common:invite_end")}
      </div>
      <div className="invite-button">
        <div
          className="button-img"
          onClick={async () => {
            setLoading(true);
            await shipmentStore!.cancleInvitation(invite);
            setLoading(false);
          }}
        >
          <img src={closeIcon} alt="mate" />
        </div>
        <div
          className="button-img_primary"
          onClick={async () => {
            setLoading(true);
            await shipmentStore!.redeemInvitation(invite);
            setLoading(false);
          }}
        >
          <img src={checkIcons} alt="check" />
        </div>
      </div>
    </Card>
  );
};

export default inject("shipmentStore")(InvitationCard);
