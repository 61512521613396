import { action, observable, makeObservable } from "mobx";

export class ModalStore {
  @observable
  isVisible: boolean = false;

  @observable
  route: string = "";

  @observable
  alreadyOpen = false;

  constructor() {
    makeObservable(this);
  }

  @action
  closeModal() {
    this.alreadyOpen = false;
    this.isVisible = false;
  }

  @action
  openModal(route: string) {
    this.alreadyOpen = true;
    this.route = route;
    this.isVisible = true;
  }
}
