import React from "react";
import "./ProfileTabBar.scss";
import { useTranslation } from "react-i18next";
import ProfileTabbarItem from "./ProfileTabbarItem";

const ProfileTabBar = ({ onChange, currentPage }: any) => {
  const { t } = useTranslation(["profile"]);
  return (
    <div className="profile-tabbar pl-20 mr-20">
      <ProfileTabbarItem
        value={t("myHugos")}
        currentPage={currentPage}
        pagename="mycubes"
        onChange={onChange}
      />
      <ProfileTabbarItem
        value={t("user")}
        currentPage={currentPage}
        pagename="user"
        onChange={onChange}
      />
      <ProfileTabbarItem
        value={t("credits")}
        currentPage={currentPage}
        pagename="credits"
        onChange={onChange}
      />
    </div>
  );
};

export default ProfileTabBar;
