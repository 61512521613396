import React, { useState } from "react";
import "./Login.scss";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import Container from "../../components/container/Container";

import hugoLogo from "../../assets/hugo_logo.png";
import TextHeaderBlock from "../../components/textheaderblock/TextHeaderBlock";
import TextInput from "../../components/TextInput/TextInput";
import { useForm } from "react-hook-form";
import PrimaryButton from "../../components/primarybutton/PrimaryButton";
import { UserStore } from "../../stores/user.store";
import { useHistory } from "react-router-dom";
import { inject } from "mobx-react";
import LoginCheckPage from "../../components/LoginCheckPage/LoginCheckPage";
import FloatingBack from "../../components/floatingback/FloatingBack";

interface LoginProps {
  userStore?: UserStore;
}

const Login = ({ userStore }: LoginProps) => {
  const { t } = useTranslation(["common"]);
  const history = useHistory();
  const { register, handleSubmit, errors, trigger } = useForm();
  const [loading, setLoading] = useState(false);
  const onSubmit = async (data: any) => {
    login(data.email, data.password);
  };

  const login = async (email: string, password: string) => {
    setLoading(true);
    if (
      userStore &&
      (await userStore.loginUser({
        identifier: email,
        password: password,
      }))
    ) {
      setLoading(false);
      history.push("/dashboard");
    } else {
      if (userStore!.currentError) {
        console.log(JSON.stringify(userStore!.currentError));
        console.log(userStore!.currentError.status);
        if (userStore!.currentError.message.includes("403")) {
          toast.error(t("errors:pleaseVerifyFirst"));
        } else {
          toast.error(t("errors:emailOrPasswordInvalidError"));
        }
      } else {
        toast.error(t("errors:emailOrPasswordInvalidError"));
      }
      setLoading(false);
    }
  };

  return (
    <LoginCheckPage>
      <FloatingBack inverted onClick={()=> {
        history.push("/")
      }}/>
      <Container>
        <div className="login">
          <img
            src={hugoLogo}
            className="header-image-illustration image mb-30"
          />
          <TextHeaderBlock title={t("login")} darkBackground />
          <form onSubmit={handleSubmit(onSubmit)} className="form">
            <TextInput
              name="email"
              placeholder={t("common:email")}
              error={errors["email"]}
              value={""}
              type="email"
              inputRef={register({
                required: true,
              })}
            />
            <TextInput
              name="password"
              placeholder={t("common:password")}
              type="password"
              error={errors["password"]}
              value={""}
              onEnter={() => {
                trigger();
              }}
              className="mt-15"
              inputRef={register({
                required: true,
              })}
            />
            <PrimaryButton
              label={t("common:login")}
              submitButton
              className="mt-30"
              inverted
              loading={loading}
              onClick={() => {}}
            />
          </form>
        </div>
      </Container>
    </LoginCheckPage>
  );
};

export default inject("userStore")(Login);
