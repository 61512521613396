import React from "react";
import "./ShopItemCard.scss";
import ShopItem from "../../models/Shopitem.model";
import Card from "../card/Card";
import addIcon from "../../assets/plus.svg";
import { inject } from "mobx-react";
import { ShopStore } from "../../stores/shop.store";
import { ModalStore } from "../../stores/modal.store";

interface ShopitemProps {
  item: ShopItem;
  shopStore?: ShopStore;
  modalStore?: ModalStore;
}

const ShopItemCard = ({ item, modalStore, shopStore }: ShopitemProps) => {
  return (
    <Card className="shop-item">
      <div
        onClick={() => {
          shopStore?.setCurrentItem(item);
          modalStore?.openModal("addToBasket");
        }}
      >
        <img src={item.image.url} />
        <div className="footer">
          <div className="title">{item.title}</div>
          <div className="subtitle">{item.shortDescription}</div>
        </div>
      </div>
    </Card>
  );
};

export default inject("shopStore", "modalStore")(ShopItemCard);
