import React from "react";
import "./MyCubeDetails.scss";
import { toast } from "react-toastify";

//@ts-ignore
import ProgressiveImage from "react-progressive-image";
//@ts-ignore
import { CopyToClipboard } from "react-copy-to-clipboard";
import copyIcon from "../../assets/icons/copy.svg";
import cubeVector from "../../assets/icons/cube.svg";

import MyCube from "../../models/MyCube.model";
import Cube from "../../models/Cube.model";
import { useTranslation } from "react-i18next";
import CubeAddress from "../cubeaddress/CubeAddress";

interface MyCubeDetailsProps {
  currentMyCube?: MyCube;
  clickable?: boolean;
  noheader?: boolean;
  showCode?: boolean;
}

const MyCubeDetails = ({
  currentMyCube,
  noheader,
  showCode,
}: MyCubeDetailsProps) => {
  const { t } = useTranslation();

  if (!currentMyCube) {
    return <></>;
  }
  const getCubeImage = (cube: Cube) => {
    if (cube.image && cube.image.formats && cube.image.formats.medium) {
      return (
        <ProgressiveImage
          src={cube.image.formats.medium.url}
          placeholder={cube.image.formats.thumbnail.url}
        >
          {(src: any, loading: boolean) => (
            <img
              style={{ opacity: loading ? 0.5 : 1 }}
              src={src}
              alt="an image"
            />
          )}
        </ProgressiveImage>
      );
    }
    return <img src={cubeVector} />;
  };

  return (
    <div className="mycube-details">
      {!noheader && (
        <div className="header">{getCubeImage(currentMyCube.cube)}</div>
      )}
      <div className="content">
        <div className="code">
          <div className="title mb-5">{currentMyCube.cube.description}</div>
          {/* <div className="code-details">
            {"c/o " + currentMyCube.shipmentcode.shipmentcode}

            {/* <div className="copy-icon">
              <CopyToClipboard
                text={currentMyCube.shipmentcode.shipmentcode}
                onCopy={() => {
                  toast.success(t("common:copysuccess"));
                }}
              >
                <img src={copyIcon} />
              </CopyToClipboard>
            </div>
          </div> */}
          <CubeAddress
            cube={currentMyCube.cube}
            shipmentcode={currentMyCube.shipmentcode.shipmentcode}
            showNameAndAddress={showCode}
          />
        </div>
      </div>
    </div>
  );
};

export default MyCubeDetails;
