import React, { useEffect } from "react";
import { Provider as MobxProvider } from "mobx-react";
import { UserStore } from "./stores/user.store";
import Navigator from "./navigation/Navigator";
import { CubeStore } from "./stores/cubes.store";
import { ModalStore } from "./stores/modal.store";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ShipmentStore } from "./stores/shipment.store";
import { MyCubeStore } from "./stores/mycube.store";
import { ShopStore } from "./stores/shop.store";

import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import Websocket from "./services/Websocket.service";

const stripePromise = loadStripe(
  "pk_test_51IIYEWKPq6yp5G49hPwic8ahlXLg6auHDg654bxBaIbsTm3jrYsxCBF5A2qXYIG8OJpyF5kr3RvqXzfpysLX32XE006ydHNnza"
);

const userStore = new UserStore();
const cubeStore = new CubeStore();
const modalStore = new ModalStore();
const shipmentStore = new ShipmentStore();
const myCubeStore = new MyCubeStore();
const shopStore = new ShopStore();

const stores = {
  userStore,
  cubeStore,
  modalStore,
  shipmentStore,
  myCubeStore,
  shopStore,
};

const websocke = new Websocket();
websocke.addStore("userStore", userStore);
websocke.addStore("shipmentStore", shipmentStore);
websocke.addStore("myCubeStore", myCubeStore);
websocke.addStore("shopStore", shopStore);

const App = () => {
  return (
    <Elements stripe={stripePromise}>
      <MobxProvider {...stores}>
        <Navigator />
        <ToastContainer />
      </MobxProvider>
    </Elements>
  );
};

export default App;
