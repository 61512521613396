import React from "react";
import "./MyCubeCard.scss";
import Cube from "../../models/Cube.model";
import cubeVector from "../../assets/icons/cube.svg";
import MyCube from "../../models/MyCube.model";
import { inject } from "mobx-react";
import { ModalStore } from "../../stores/modal.store";
import { useTranslation } from "react-i18next";
import { MyCubeStore } from "../../stores/mycube.store";
import { useHistory } from "react-router-dom";

interface MyCubeCardProps {
  myCube?: MyCube;
  isAdd?: boolean;
  modalStore?: ModalStore;
  myCubeStore?: MyCubeStore;
  className?: any;
}

const MyCubeCard = ({
  myCube,
  isAdd,
  modalStore,
  myCubeStore,
  className,
}: MyCubeCardProps) => {
  const { t } = useTranslation();
  const history = useHistory();
  const renderImage = (cube: Cube) => {
    if (cube && cube.image && cube.image.formats && cube.image.formats.medium) {
      return <img src={cube.image.formats.medium.url} className="img-full" />;
    } else {
      return <img src={cubeVector} className="img-placeholder" />;
    }
  };
  if (isAdd) {
    return (
      <div
        className={`my-cube-card ${className}`}
        onClick={() => {
          modalStore!.openModal("addmycube");
        }}
      >
        <div className="header">
          <img src={cubeVector} className="img-placeholder" />
        </div>
        <div className="p-20 footer">
          <div className="cube">{t("profile:addHugoCard_1")}</div>
          <div className="add">{t("profile:addHugoCard_2")}</div>
        </div>
      </div>
    );
  }

  if (!myCube) {
    return <></>;
  }

  const generateAddress = () => {
    const { address } = myCube.cube;
    return (
      <div className="subtitle">
        <div>{address.street}</div>
        <div>
          {address.zip} {address.city}
        </div>
      </div>
    );
  };

  return (
    <div
      className={`my-cube-card ${className}`}
      onClick={() => {
        myCubeStore?.setCurrentMyCube(myCube);
        //  modalStore?.openModal("cubedetails");
        history.push("/cubedetails?cubeid=" + myCube.cube.id);
      }}
    >
      <div className="header">{renderImage(myCube.cube)}</div>
      <div className="p-20 footer">
        <div className="cube">{myCube.cube.description}</div>
        {/* {generateAddress()}*/}
      </div>
    </div>
  );
};

export default inject("modalStore", "myCubeStore")(MyCubeCard);
