import axios from "axios";
import CreateAddress from "../models/CreateAddress.model";
import Invitation from "../models/Invitations.model";
import { OrderPart } from "../models/Order.model";

const BACKENDURL = process.env.REACT_APP_BACKEND_URL
  ? process.env.REACT_APP_BACKEND_URL
  : "http://localhost:1337";

const generateConfig = () => {
  return {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("hugo_jwt"),
    },
  };
};

//USER

const me = () => {
  return axios.get(BACKENDURL + "/users/me", generateConfig());
};

const updateAddress = (address: CreateAddress) => {
  return axios.put(
    BACKENDURL + "/users/me",
    { address: address },
    generateConfig()
  );
};

const updateUser = (user: any) => {
  return axios.put(BACKENDURL + "/users/me", user, generateConfig());
};

const addPayment = (paymentid: string) => {
  return axios.post(
    BACKENDURL + "/users/me/payment",
    { paymentId: paymentid },
    generateConfig()
  );
};

//CUBES

const loadCubes = () => {
  return axios.get(BACKENDURL + "/cubes", generateConfig());
};

const createCubeForMe = (cubeId: number) => {
  return axios.post(
    BACKENDURL + "/mycubes",
    { cubeid: cubeId },
    generateConfig()
  );
};

const deleteCubeForMe = (cubeid: number) => {
  return axios.delete(BACKENDURL + "/mycubes/" + cubeid, generateConfig());
};

const getMyCubeById = (cubeid: number) => {
  return axios.get(BACKENDURL + "/mycubes/" + cubeid, generateConfig());
};

const getAllMyCubes = () => {
  return axios.get(BACKENDURL + "/mycubes", generateConfig());
};

const loadCube = (cubeid: number) => {
  return axios.get(BACKENDURL + "/cubes/" + cubeid, generateConfig());
};

//shipments
const loadInvitations = () => {
  return axios.get(BACKENDURL + "/shareshipments", generateConfig());
};

const loadShipments = () => {
  return axios.get(BACKENDURL + "/shipments", generateConfig());
};
const updateShipmentById = (shipmentId: number, data: any) => {
  return axios.put(
    BACKENDURL + "/shipments/" + shipmentId,
    data,
    generateConfig()
  );
};

const loadShipmentsForCube = (id: string) => {
  return axios.get(BACKENDURL + "/shipments?cubeid=" + id, generateConfig());
};

const loadShipmentsByShareToken = (sharetoken: string) => {
  return axios.get(BACKENDURL + "/shipments/" + sharetoken, generateConfig());
};

const redeemInvitation = (invitation: Invitation) => {
  return axios.get(
    BACKENDURL + "/shareshipments/" + invitation.token,
    generateConfig()
  );
};

const cancleInvitation = (invitation: Invitation) => {
  return axios.delete(
    BACKENDURL + "/shareshipments/" + invitation.token,
    generateConfig()
  );
};

const invitePerEmail = (email: string, shipmentid: number) => {
  return axios.post(
    BACKENDURL + "/shareshipments",
    {
      shipmentid: shipmentid,
      useremail: email,
    },
    generateConfig()
  );
};

const createOrder = (orderparts: any) => {
  return axios.post(
    BACKENDURL + "/orders",
    {
      orderparts,
    },
    generateConfig()
  );
};

//shop

const getShopItems = () => {
  return axios.get(BACKENDURL + "/shopitems", generateConfig());
};

const getAllOrders = () => {
  return axios.get(BACKENDURL + "/orders", generateConfig());
};

const getOneOrder = (id: number) => {
  return axios.get(BACKENDURL + "/orders/" + id, generateConfig());
};

const getOneProducer = (id: string) => {
  return axios.get(BACKENDURL + "/producers/" + id, generateConfig());
};

export {
  me,
  updateAddress,
  updateUser,
  deleteCubeForMe,
  loadCubes,
  loadCube,
  createCubeForMe,
  loadShipments,
  loadShipmentsForCube,
  getMyCubeById,
  getAllMyCubes,
  loadShipmentsByShareToken,
  loadInvitations,
  cancleInvitation,
  redeemInvitation,
  invitePerEmail,
  getShopItems,
  addPayment,
  createOrder,
  getAllOrders,
  getOneOrder,
  getOneProducer,
  updateShipmentById,
};
