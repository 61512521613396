import { inject, observer } from "mobx-react";
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";

const LoginCheckPage = ({ children, userStore }: any) => {
  const history = useHistory();
  useEffect(() => {
    userStore!.getMe();
  }, [history, userStore]);

  useEffect(() => {
    if (userStore.isAuthenticated) {
      history.push("/dashboard");
    }
  }, [userStore.isAuthenticated]);
  return <div>{children}</div>;
};

export default inject("userStore")(observer(LoginCheckPage));
